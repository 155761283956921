import ConsentCategory from "common/ConsentCategory.ts";
import { capacitorMode } from "buildConfig.ts";
import CookieContentType from "./CookieContentType.ts";

type Configuration = {
  name: string;
  category: ConsentCategory;
  contentType: CookieContentType;
  maxAgeDays?: number;
  path?: string;
};

type ConfigurationFunction = (props: { id: string }) => Configuration;

const maxAgeDays = 365;

const cookieConfigurations = {
  consent: {
    category: ConsentCategory.Essential,
    name: "consent",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  auth: {
    category: ConsentCategory.Essential,
    name: "auth",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  darkMode: {
    category: ConsentCategory.Preferences,
    name: "darkMode",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  nonce: {
    category: ConsentCategory.Essential,
    name: "nonce",
  } as Configuration,
  browserIsNotSupported: {
    category: ConsentCategory.Essential,
    name: "browserIsNotSupported",
    contentType: CookieContentType.Json,
  } as Configuration,
  locale: {
    category: ConsentCategory.Essential,
    name: "locale",
    maxAgeDays,
  } as Configuration,
  mobileMode: {
    category: ConsentCategory.Essential,
    name: "mobileMode",
    contentType: CookieContentType.Json,
  } as Configuration,
  purchaseOrder: {
    category: ConsentCategory.Essential,
    name: "purchaseOrder",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  url:
    capacitorMode &&
    ({
      category: ConsentCategory.Essential,
      name: "url",
      maxAgeDays: 30,
    } as Configuration),
  conversations: {
    category: ConsentCategory.Essential,
    name: "conversations",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  eventGuestsSearchParameters: (({ id }: { id: string }) => ({
    category: ConsentCategory.Essential,
    name: `EventGuests.searchParameters_${id}`,
    contentType: CookieContentType.Json,
    maxAgeDays,
  })) as ConfigurationFunction,
  permanentGuestListGuestsSearchParameters: (({ id }: { id: string }) => ({
    category: ConsentCategory.Essential,
    name: `PermanentGuestListGuests.searchParameters_${id}`,
    contentType: CookieContentType.Json,
    maxAgeDays,
  })) as ConfigurationFunction,
  reverseGuestNames: {
    category: ConsentCategory.Essential,
    name: "reverseGuestNames",
    contentType: CookieContentType.Json,
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  firstRecordedManagerUse: {
    category: ConsentCategory.Analytics,
    name: "firstRecordedManagerUse",
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  useTicketsOnScan: {
    category: ConsentCategory.Essential,
    name: "useTicketsOnScan",
    contentType: CookieContentType.Json,
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  lastSetEventGuestEntryGuestListId: {
    category: ConsentCategory.Essential,
    name: "lastSetEventGuestEntryGuestListId",
    maxAgeDays: 30,
    path: "/manager",
  } as Configuration,
  eventGuestEntrySection: {
    category: ConsentCategory.Essential,
    name: "eventGuestEntrySection",
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  invitationsHomeOpenSearchParameters: {
    category: ConsentCategory.Essential,
    name: `InvitationsHomeOpen.searchParameters`,
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  invitationsHomePersonalSearchParameters: {
    category: ConsentCategory.Essential,
    name: `InvitationsHomePersonal.searchParameters`,
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  invitationsHomeTemplatesSearchParameters: {
    category: ConsentCategory.Essential,
    name: `InvitationsHomeTemplates.searchParameters`,
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  invitationsHomeSection: {
    category: ConsentCategory.Essential,
    name: "InvitationsHome.section",
    maxAgeDays,
  },
  eventStatisticsGroupBy: {
    category: ConsentCategory.Essential,
    name: "eventStatisticsGroupBy",
    contentType: CookieContentType.Json,
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  eventStatisticsUnit: {
    category: ConsentCategory.Essential,
    name: "eventStatisticsUnit",
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  eventsHomeSection: {
    category: ConsentCategory.Essential,
    name: "EventsHome.section",
    maxAgeDays,
  },
  eventsHomeEventsSearchParameters: {
    category: ConsentCategory.Essential,
    name: "EventsHomeEvents.searchParameters",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  eventsHomeTemplatesSearchParameters: {
    category: ConsentCategory.Essential,
    name: "EventsHomeTemplates.searchParameters",
    contentType: CookieContentType.Json,
    maxAgeDays,
  } as Configuration,
  lastCreateEventVenueId: {
    category: ConsentCategory.Essential,
    name: "lastCreateEventVenueId",
    maxAgeDays: 30,
    path: "/manager",
  } as Configuration,
  lastCreateEventEventTemplateId: {
    category: ConsentCategory.Essential,
    name: "lastCreateEventEventTemplateId",
    maxAgeDays: 30,
    path: "/manager",
  } as Configuration,
  lastCreateEventTemplateVenueId: {
    category: ConsentCategory.Essential,
    name: "lastCreateEventTemplateVenueId",
    maxAgeDays: 30,
    path: "/manager",
  } as Configuration,
  permanentGuestListEntrySection: {
    category: ConsentCategory.Essential,
    name: "permanentGuestListEntrySection",
    maxAgeDays,
    path: "/manager",
  } as Configuration,
  lastCreateUserSetPassword: {
    category: ConsentCategory.Essential,
    name: "lastCreateUserSetPassword",
    contentType: CookieContentType.Json,
    maxAgeDays: 30,
  } as Configuration,
  gclid: {
    category: ConsentCategory.Advertising,
    name: "gclid",
    contentType: CookieContentType.Text,
    maxAgeDays: 90,
  } as Configuration,
  wbraid: {
    category: ConsentCategory.Advertising,
    name: "wbraid",
    contentType: CookieContentType.Text,
    maxAgeDays: 90,
  } as Configuration,
  eventIdFieldSection: {
    category: ConsentCategory.Essential,
    name: "eventIdFieldSection",
    contentType: CookieContentType.Text,
    maxAgeDays,
  },
};

export default cookieConfigurations;
