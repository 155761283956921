import Feature from "common/Feature.ts";
import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "common/UserFriendlyMessageError.ts";
import i18next from "i18next";
import { LogValuesError } from "./LogValuesError.ts";

export type Options = ParentOptions & { targetId: string; feature: Feature };

export default class MissingFeatureError
  extends UserFriendlyMessageError
  implements LogValuesError
{
  public static className = "MissingFeatureError";

  readonly logValues?: { [key: string]: any };

  constructor(messageOrOptions?: string | Options, options?: Options) {
    const message =
      typeof messageOrOptions === "string"
        ? messageOrOptions
        : "Missing feature.";
    const {
      userFriendlyMessage = i18next.t(
        "MissingFeatureError.userFriendlyMessage",
        {
          defaultValue: message,
        },
      ),
      targetId,
      feature,
    } = (typeof messageOrOptions === "object" && messageOrOptions) ||
    options ||
    {};
    super(message, { userFriendlyMessage });
    this.name = MissingFeatureError.className;
    this.logValues = { targetId, feature };
  }
}
