import { createContext, use } from "react";
import { SafeAreaInsets } from "./safeAreaInsets.ts";

export type AppMeasurementsContextValue = {
  width: number;
  height: number;
  safeAreaInsets: SafeAreaInsets;
};

const AppMeasurementsContext = createContext<AppMeasurementsContextValue>(null);

export default AppMeasurementsContext;

export const useAppMeasurements = () => use(AppMeasurementsContext);
