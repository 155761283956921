import { TFunction } from "i18next";
import {
  base64FromUrlSafe,
  base64ToUrlSafe,
  base64ToByteArray,
  base64Encode,
} from "./base64.ts";

export enum GraphIdType {
  Account = "ac",
  Venue = "ve",
  Event = "ev",
  GuestList = "g",
  User = "u",
  Invoice = "in",
  PermanentGuestList = "pg",
  PermanentGuestListEntry = "pe",
  Product = "pr",
  PurchaseOrder = "po",
  NewsletterEmail = "nle",
  EventTemplate = "et",
  GuestListTemplate = "gt",
  GuestConfirmation = "gec",
  Ticket = "t",
  Invitation = "i",
  InvitationTemplate = "it",
  GuestEntry = "ge",
  AutoMessage = "am",
}

export function getAbsoluteGraphIdUrl(graphId: string) {
  return `https://attendium.com/.${graphId}`;
}

export function getGraphIdTypename(type?: GraphIdType) {
  return Object.entries(GraphIdType).find((entry) => entry[1] === type)?.[0];
}

export function getGraphIdTypeName(
  type: GraphIdType,
  t: TFunction,
): string | null {
  return t("GraphId.typeName", {
    context: getGraphIdTypename(type),
    defaultValue: getGraphIdTypename(type),
  });
}

export function getGraphIdTypeUrlPath(type: GraphIdType): string | null {
  const path =
    (type === GraphIdType.Account && "accounts") ||
    (type === GraphIdType.Venue && "venues") ||
    (type === GraphIdType.Event && "events") ||
    (type === GraphIdType.EventTemplate && "event-templates") ||
    null;
  return path ? `/manager/${path}` : null;
}

const LENGTH_BASE64_UUID = 22;

export default class GraphId {
  type: GraphIdType;

  uuids: Uint8Array[];

  constructor(type: GraphIdType, uuids: Uint8Array[]) {
    this.type = type;
    this.uuids = uuids;
  }

  static fromString(string: string): GraphId | null {
    if (!string) return null;

    string = string.replaceAll(".", "-"); // See toString()

    const stringLength = string.length;
    const discriminatorLength = stringLength % LENGTH_BASE64_UUID;
    const uuidCount = (stringLength - discriminatorLength) / LENGTH_BASE64_UUID;
    const type = string.substring(0, discriminatorLength) as GraphIdType;

    const uuids = [];

    try {
      for (let i = 0; i < uuidCount; i += 1)
        uuids.push(
          base64ToByteArray(
            base64FromUrlSafe(
              string.substring(
                discriminatorLength + i * LENGTH_BASE64_UUID,
                discriminatorLength + (i + 1) * LENGTH_BASE64_UUID,
              ),
            ),
          ),
        );
    } catch (error) {
      console.error(error);
      return null;
    }

    return new GraphId(type, uuids);
  }

  toString() {
    let string: string = this.type;
    for (let { length } = this.uuids, i = 0; i < length; i += 1)
      string += base64ToUrlSafe(base64Encode(this.uuids[i]));

    // Since -- can be replaced by em dash (—) by some software, we need to replace "--" with ".-".
    // Note that we don't simply replace all dashes with dots, as "..." can also be replaced with the ellipsis character (…) by some software.
    // We also prefer ".-" over "-." as the latter could be misinterpreted as being the end of a sentence.
    while (string.includes("---")) string = string.replaceAll("---", ".-.");
    while (string.includes("--")) string = string.replaceAll("--", ".-");

    return string;
  }
}
