/**
 * @generated SignedSource<<661d5fe6fecdcd280947650c5e47faf3>>
 * @relayHash 69793679668fbf37e7fd0dfb62362310
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 69793679668fbf37e7fd0dfb62362310

import { ConcreteRequest } from 'relay-runtime';
export type UpgradeSubscriptionPlanLinkAccountQuery$variables = {
  id: string;
};
export type UpgradeSubscriptionPlanLinkAccountQuery$data = {
  readonly account: {
    readonly currentUserHasAdministerPermission: boolean;
    readonly id: string;
  } | null | undefined;
};
export type UpgradeSubscriptionPlanLinkAccountQuery = {
  response: UpgradeSubscriptionPlanLinkAccountQuery$data;
  variables: UpgradeSubscriptionPlanLinkAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "account",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": "currentUserHasAdministerPermission",
        "args": [
          {
            "kind": "Literal",
            "name": "permission",
            "value": "ADMINISTER"
          }
        ],
        "kind": "ScalarField",
        "name": "currentUserHasPermission",
        "storageKey": "currentUserHasPermission(permission:\"ADMINISTER\")"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpgradeSubscriptionPlanLinkAccountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpgradeSubscriptionPlanLinkAccountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "69793679668fbf37e7fd0dfb62362310",
    "metadata": {},
    "name": "UpgradeSubscriptionPlanLinkAccountQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ca052bad68d3efe91810a1846170e0e5";

export default node;
