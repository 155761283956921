import loadScript from "load-script";
import { Location, Params } from "react-router";
import {
  ConsentStatus,
  ConsentType,
  FirebaseAnalytics,
} from "@capacitor-firebase/analytics";
import {
  capacitorMode,
  googleAnalyticsMeasurementId,
  googleAdsAccountId,
  metaPixelId,
} from "buildConfig.ts";
import ConsentCategory from "../ConsentCategory.ts";
import CookieManager from "../CookieManager.ts";
import cookieConfigurations from "../cookieConfigurations.ts";
import Consent from "./Consent.ts";

export default class Tracking {
  private nonce?: string;

  private url?: string;

  private urlSearchParams?: URLSearchParams;

  private firebase = capacitorMode ? FirebaseAnalytics : null;

  private cookieManager: CookieManager;

  private consent?: Consent;

  public constructor({
    nonce,
    cookieManager,
  }: {
    nonce?: string;
    cookieManager: CookieManager;
  }) {
    this.nonce = nonce;
    this.cookieManager = cookieManager;
  }

  public init({ consent }: { consent: Consent }) {
    if (!capacitorMode) {
      if (googleAnalyticsMeasurementId || googleAdsAccountId) {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments);
        };

        loadScript(
          `https://www.googletagmanager.com/gtag/js?id=${encodeURIComponent(googleAnalyticsMeasurementId || googleAdsAccountId)}`,
          {
            attrs: { nonce: this.nonce },
          },
        );
      }

      if (metaPixelId) {
        if (!window.fbq) {
          const fbq = (...args: any) => {
            if (fbq.callMethod) fbq.callMethod(...args);
            else fbq.queue.push(args);
          };
          window.fbq = fbq;
          if (!window._fbq) window._fbq = fbq;
          fbq.push = fbq;
          fbq.loaded = true;
          fbq.version = "2.0";
          fbq.queue = [];
        }

        loadScript(`https://connect.facebook.net/en_US/fbevents.js`, {
          attrs: { nonce: this.nonce },
        });
      }
    }

    this.setConsent(consent);

    if (!capacitorMode) {
      const { gtag, fbq } = window;

      if (gtag) {
        gtag("js", new Date());
        gtag("consent", "default", {
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          analytics_storage: "denied",
        });
        if (googleAnalyticsMeasurementId)
          gtag("config", googleAnalyticsMeasurementId, {
            send_page_view: false,
          });
        if (googleAdsAccountId) gtag("config", googleAdsAccountId);
      }

      fbq?.("init", metaPixelId);
    }
  }

  public setConsent(consent: Consent) {
    if (consent)
      this.trackCustomEvent({
        name: "consent_select",
        props: {
          first: !this.consent,
          acceptedCategories: JSON.stringify(consent.acceptedCategories),
        },
      });

    this.consent = consent;
    const { gtag, fbq } = window;
    const { firebase } = this;

    const analytics =
      consent &&
      (consent.acceptedCategories === null ||
        consent.acceptedCategories?.includes(ConsentCategory.Analytics));
    const advertising =
      consent &&
      (consent.acceptedCategories === null ||
        consent.acceptedCategories?.includes(ConsentCategory.Advertising));

    gtag?.("consent", "update", {
      ad_storage: advertising ? "granted" : "denied",
      analytics_storage: analytics ? "granted" : "denied",
    });

    if (firebase) {
      firebase.setConsent({
        type: ConsentType.AdStorage,
        status: advertising ? ConsentStatus.Granted : ConsentStatus.Denied,
      });
      firebase.setConsent({
        type: ConsentType.AnalyticsStorage,
        status: analytics ? ConsentStatus.Granted : ConsentStatus.Denied,
      });
      firebase.setConsent({
        type: ConsentType.AdUserData,
        status: ConsentStatus.Denied,
      });
      firebase.setConsent({
        type: ConsentType.AdPersonalization,
        status: ConsentStatus.Denied,
      });
    }

    fbq?.("consent", analytics && advertising ? "grant" : "revoke");

    this.updateAdTrackingCookies();
  }

  private updateAdTrackingCookies() {
    const { consent, cookieManager, urlSearchParams } = this;

    const allowed =
      consent?.acceptedCategories === null ||
      consent?.acceptedCategories?.includes(ConsentCategory.Advertising);

    if (allowed) {
      const gclid = urlSearchParams?.get("gclid");
      const wbraid = urlSearchParams?.get("wbraid");

      if (gclid)
        cookieManager.set({ ...cookieConfigurations.gclid, value: gclid });
      if (wbraid)
        cookieManager.set({ ...cookieConfigurations.wbraid, value: wbraid });
    } else {
      cookieManager.delete(cookieConfigurations.gclid);
      cookieManager.delete(cookieConfigurations.wbraid);
    }
  }

  public trackStandardEvent({
    name,
    metaName = name,
    googleName = name,
    props,
    metaProps = props,
    googleProps = props,
  }: {
    name?: string;
    metaName?: string;
    googleName?: string;
    props?: { [name: string]: any };
    metaProps?: { [name: string]: any };
    googleProps?: { [name: string]: any };
  }) {
    if (googleName) {
      window.gtag?.("event", googleName, googleProps);
      this.firebase?.logEvent({ name: googleName, params: googleProps });
    }
    if (metaName)
      window.fbq?.("track", metaName, { path: this.url, ...metaProps });
  }

  public trackCustomEvent({
    name,
    metaName = name,
    googleName = name,
    props,
    metaProps = props,
    googleProps = props,
  }: {
    name?: string;
    metaName?: string;
    googleName?: string;
    props?: { [name: string]: any };
    metaProps?: { [name: string]: any };
    googleProps?: { [name: string]: any };
  }) {
    if (googleName) {
      window.gtag?.("event", googleName, googleProps);
      this.firebase?.logEvent({ name: googleName, params: googleProps });
    }
    if (metaName)
      window.fbq?.("trackCustom", metaName, { path: this.url, ...metaProps });
  }

  public setLocation({
    location,
    params,
  }: {
    location: Pick<Location, "pathname" | "search" | "hash">;
    params: Params;
  }) {
    // Remove sensitive search params for security purposes
    this.urlSearchParams = new URLSearchParams(location.search);
    this.urlSearchParams.forEach((value, key) => {
      const lowercaseKey = key.toLowerCase();
      if (
        lowercaseKey === "s" ||
        lowercaseKey.includes("auth") ||
        lowercaseKey.includes("code")
      )
        this.urlSearchParams.delete(key);
    });
    let url = location.pathname;
    if (this.urlSearchParams.size) url += `?${this.urlSearchParams.toString()}`;
    url += location.hash;

    // Mask all params for security purposes
    Object.entries(params).forEach(([name, value]) => {
      url = url.replace(value, `{${name}}`);
    });

    if (this.url !== url) {
      window.gtag?.("event", "page_view", { page_location: url });
      window.fbq?.("track", "PageView", { path: url });
      this.firebase?.setCurrentScreen({ screenName: location.pathname });

      this.url = url;
      this.updateAdTrackingCookies();
    }
  }

  public trackGoogleAdsConversion({ conversionId }: { conversionId: string }) {
    window.gtag?.("event", "conversion", { send_to: conversionId });
  }
}
