import { createContext, ReactElement, ReactNode, use, useState } from "react";
import { generateUniqueId } from "./unique-id.ts";

export type NotificationType = "info" | "error" | "warning";

export type Notification = {
  type?: NotificationType;
  message?;
  error?: any;
  autoHide?: boolean;
};

export type AddNotificationArguments = ReactNode | Notification;

export type NotificationsContextValue = {
  notifications: Notification[];
  addNotification: (notification: AddNotificationArguments) => Notification;
  dismissNotifications: (notifications: Notification[]) => void;
};

const NotificationsContext = createContext<NotificationsContextValue>(
  undefined!,
);

export const useNotifications = () => use(NotificationsContext);

export function useCreateNotificationsContext(): NotificationsContextValue {
  const [value, setValue] = useState<NotificationsContextValue>({
    notifications: [],
    addNotification: (someNotification) => {
      const type =
        someNotification.type || (someNotification.error && "error") || "info";
      const notification = {
        autoHide: type === "info",
        ...(typeof someNotification === "string"
          ? { message: someNotification }
          : someNotification),
        id: generateUniqueId(),
        type,
      };
      setValue((notificationsContext) => {
        const { notifications } = notificationsContext;
        return {
          ...notificationsContext,
          notifications: [...notifications, notification],
        };
      });
      return notification;
    },
    dismissNotifications: (notifications) =>
      setValue((notificationsContext) => ({
        ...notificationsContext,
        notifications: notificationsContext.notifications.filter(
          (notification) => !notifications.includes(notification),
        ),
      })),
  });

  return value;
}

export default NotificationsContext;
