import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { RouteObject, redirect } from "react-router";
import detailsRoute from "./PermanentGuestListDetails/route.ts";
import deleteRoute from "./DeletePermanentGuestList/route.ts";
import fieldsRoute from "./PermanentGuestListFields/route.ts";
import passesRoute from "./PermanentGuestListPasses/route.ts";
import permissionsRoute from "./PermanentGuestListPermissions/route.ts";

export default {
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./PermanentGuestListSettingsRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "delete", ...deleteRoute },
        { path: "fields", ...fieldsRoute },
        { path: "passes", ...passesRoute },
        { path: "permissions", ...permissionsRoute },
      ],
    },
  ],
} as RouteObject;
