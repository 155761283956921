export default class GraphqlError extends Error {
  public static className = "GraphqlError";

  public errors: any[];

  constructor(message: string, otherOptions: any) {
    super(message);
    this.name = GraphqlError.className;
    Object.assign(this, otherOptions);
  }
}
