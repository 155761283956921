import logger from "common/logger.ts";
import getUserFriendlyErrorMessage from "common/getUserFriendlyErrorMessage.ts";
import {
  packageName,
  packageVersion,
  productionMode,
  sentryDsn,
  sentryTunnelUrl,
} from "buildConfig.ts";

function shouldLogInSentry(error: any) {
  return error && !getUserFriendlyErrorMessage(error);
}

// Since sentry does not provide an isomorphic interface
export default function initLogger({
  sentry,
  breadCrumbs,
}: {
  sentry: any;
  breadCrumbs?: boolean;
}): void {
  logger.addHandler(({ error, ...otherProps }) =>
    console.error(error, { error, otherProps }),
  );

  if (sentryDsn && productionMode) {
    logger.addHandler((props) => {
      const { error, user, extras, reference } = props;

      if (!reference && shouldLogInSentry(error)) {
        sentry.withScope((scope) => {
          if (user) scope.setUser(user);
          scope.setExtras({ ...extras, ...error.logValues });
          sentry.captureException(error);
        });

        return {
          ...props,
          reference: sentry.lastEventId?.(),
        };
      }

      return props;
    });

    sentry.init({
      dsn: sentryDsn,
      release: `${packageName}@${packageVersion}`,
      autoSessionTracking: false,
      tunnel: sentryTunnelUrl, // Because some ad-blockers will block sentry events sent directly to sentry
      beforeSend(event, hint) {
        const error = hint?.originalException;

        if (!shouldLogInSentry(error)) return null; // Errors which have userFriendlyMessage should not be sent to sentry as they are considered expected and "normal" errors

        // Don't send query params, as they can contain sensitive information like tokens etc
        if (event.request?.url.includes("?"))
          event.request.url = event.request.url.substring(
            0,
            event.request.url.indexOf("?"),
          );
        return event;
      },
      integrations: (integrations: any[]) =>
        integrations.filter(
          ({ name }) =>
            (breadCrumbs || name !== "Breadcrumbs") && name !== "Dedupe",
        ),
    });
  }
}
