import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { RouteObject } from "react-router";

export default {
  children: [
    {
      path: "*",
      lazy: () => getLazyRouteProps(import("./index.tsx")),
    },
  ],
} as RouteObject;
