import { useCookieManager } from "common/CookieManagerContext.tsx";
import cookieConfigurations from "common/cookieConfigurations.ts";
import { createContext, use, useState } from "react";

export type DarkModeContextValue = {
  darkMode?: boolean;
  toggleDarkMode: () => void;
  setDarkMode: (darkMode: boolean) => void;
  destroy?: () => void;
};

const DarkModeContext = createContext<DarkModeContextValue>(null);

export const useDarkMode = () => use(DarkModeContext);

const getSystemDarkMode = () => {
  if (typeof window !== "undefined" && window.matchMedia) {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  return undefined;
};

export function useCreateDarkModeContext({
  defaultDarkMode,
}: {
  defaultDarkMode?: boolean;
}): DarkModeContextValue {
  const cookieManager = useCookieManager();

  const [value, setValue] = useState(() => {
    let darkMode;
    const cookie = cookieManager.get(cookieConfigurations.darkMode);
    if (typeof cookie === "boolean") darkMode = cookie;
    else {
      const systemDarkMode = getSystemDarkMode();
      if (systemDarkMode !== undefined) darkMode = systemDarkMode;
      else darkMode = defaultDarkMode;
    }

    const setDarkMode = (newDarkMode: boolean, adjustCookie = true) => {
      if (adjustCookie) {
        const systemDarkMode = getSystemDarkMode();
        if (newDarkMode === systemDarkMode)
          cookieManager.delete(cookieConfigurations.darkMode);
        else
          cookieManager.set({
            ...cookieConfigurations.darkMode,
            value: newDarkMode,
          });
      }
      setValue((oldValue) => ({
        ...oldValue,
        darkMode: newDarkMode,
      }));
    };

    let destroy: (() => void) | undefined;

    if (typeof window !== "undefined" && window.matchMedia) {
      const listener = (e: MediaQueryListEvent) => {
        if (
          typeof cookieManager.get(cookieConfigurations.darkMode) === "boolean"
        )
          return;
        setDarkMode(e.matches, false);
      };
      const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
      if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener("change", listener);
        destroy = () => mediaQueryList.removeEventListener("change", listener);
      } else if (mediaQueryList.addListener) {
        mediaQueryList.addListener(listener);
        destroy = () => mediaQueryList.removeListener(listener);
      }
    }

    return {
      darkMode,
      setDarkMode,
      toggleDarkMode: () =>
        setValue((oldValue) => {
          const newDarkMode = !oldValue.darkMode;
          const systemDarkMode = getSystemDarkMode();
          if (newDarkMode === systemDarkMode)
            cookieManager.delete(cookieConfigurations.darkMode);
          else
            cookieManager.set({
              ...cookieConfigurations.darkMode,
              value: newDarkMode,
            });

          return {
            ...oldValue,
            darkMode: newDarkMode,
          };
        }),
      destroy,
    };
  });

  return value;
}

export default DarkModeContext;
