import { kebabCase } from "change-case";
import * as stylex from "@stylexjs/stylex";
import { SafeAreaInsets } from "./safeAreaInsets.ts";
import { Theme } from "./Theme.ts";

export function stringifyCss(css: { [key: string]: any }) {
  return Object.entries(css)
    .map(
      ([key, value]) =>
        `${key.startsWith("--") ? key : kebabCase(key)}: ${value}`,
    )
    .join(";");
}

export const themeVarsCss = (theme: Theme) =>
  Object.entries(theme).reduce((accumulator, currentValue) => {
    const [name, value] = currentValue;
    accumulator[`--${kebabCase(name)}`] =
      typeof value === "number" && name !== "lineHeight" ? `${value}px` : value;
    return accumulator;
  }, {});

export const safeAreaInsetVarsCss = (safeAreaInsets: SafeAreaInsets) => ({
  "--safe-area-inset-top": `${safeAreaInsets?.top || 0}px`,
  "--safe-area-inset-right": `${safeAreaInsets?.right || 0}px`,
  "--safe-area-inset-bottom": `${safeAreaInsets?.bottom || 0}px`,
  "--safe-area-inset-left": `${safeAreaInsets?.left || 0}px`,
});

export const mainCss = {
  background: "var(--background-color)",
  fontFamily: "var(--font-family)",
  fontWeight: "normal",
  fontSize: "var(--font-size)",
  color: "var(--text-color)",
  lineHeight: "var(--line-height)",
};

export const hCss = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
};

export const h1Css = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
  fontSize: 44,
};

export const h2Css = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
  fontSize: 30,
};

export const h3Css = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
  fontSize: 24,
};

export const h4Css = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
  fontSize: 20,
};

const effectiveLineHeight = "calc(var(--font-size) * var(--line-height))";

export const pCss = {
  ":not(:last-child)": {
    marginBottom: effectiveLineHeight,
  },
};

export const strongCss = {
  fontWeight: "500",
};

export const olCss = {
  ":not(:last-child)": {
    marginBottom: effectiveLineHeight,
  },
};

export const ulCss = {
  ":not(:last-child)": {
    marginBottom: effectiveLineHeight,
  },
};

export const pageTitleCss = {
  color: "var(--header-color)",
  marginBottom: "var(--spacing)",
  fontWeight: "500",
  fontSize: 28,
  marginTop: 0,
};

export const groupTitleCss = {
  color: "var(--header-color)",
  fontWeight: "500",
  fontSize: 17,
};

export const noteCss = {
  fontSize: "var(--small-font-size)",
  color: "var(--note-color)",
};

export const errorNoteCss = {
  color: "var(--error-color)",
  fontSize: "var(--small-font-size)",
};

export const aCss = {
  color: "var(--main-color)",
  cursor: "pointer",
};

export const discreteACss = {
  cursor: "pointer",
  color: "var(--note-color)",
};

export const paddedCss = {
  paddingTop: "var(--padding-top)",
  paddingRight: "var(--padding-right)",
  paddingBottom: "var(--padding-bottom)",
  paddingLeft: "var(--padding-left)",
};

export const halfPaddedCss = {
  paddingTop: "calc(var(--padding-top) / 2)",
  paddingRight: "calc(var(--padding-right) / 2)",
  paddingBottom: "calc(var(--padding-bottom) / 2)",
  paddingLeft: "calc(var(--padding-left) / 2)",
};

export const doublePaddedCss = {
  paddingTop: "calc(2 * var(--padding-top))",
  paddingRight: "calc(2 * var(--padding-right))",
  paddingBottom: "calc(2 * var(--padding-bottom))",
  paddingLeft: "calc(2 * var(--padding-left))",
};

export const roundedBorderedCss = ({
  width = 1,
  radius = "var(--round-corner-border-radius)",
  color = "var(--separator-color)",
}: {
  width?: number | string;
  radius?: number | string;
  color?: string;
} = {}) => ({
  borderStyle: "solid",
  borderWidth: width,
  borderRadius: radius,
  borderColor: color,
});

export const darkModeConditionalCss = (
  darkMode: boolean | undefined,
  css: any,
) =>
  (darkMode === undefined && {
    "@media (prefers-color-scheme: dark)": css,
  }) ||
  (darkMode && css) ||
  {};

export const commonStyles = stylex.create({
  main: mainCss,
  roundedBordered: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: "var(--round-corner-border-radius)",
    borderColor: "var(--separator-color)",
  },
  groupTitle: groupTitleCss,
  strong: strongCss,
  note: noteCss,
  h1: h1Css,
  h2: h2Css,
  h3: h3Css,
  h4: h4Css,
  padded: paddedCss,
  doublePadded: doublePaddedCss,
  errorNote: errorNoteCss,
  ul: ulCss,
  ol: olCss,
  p: pCss,
  flex1: { flex: 1 },
  marginAuto: { margin: "auto" },
  minHeight: (minHeight) => ({ minHeight }),
  width: (width) => ({ width }),
  height: (height) => ({ height }),
  positionRelative: { position: "relative" },
  textWrapBalance: { textWrap: "balance" },
  textAlignCenter: { textAlign: "center" },
});
