import { createContext, use, ComponentType } from "react";
import { Theme } from "./Theme.ts";

const ThemeContext = createContext<Theme>(undefined!);

export const useTheme = () => use(ThemeContext);

export function withTheme<P extends { theme?: Theme }>(
  WrappedComponent: ComponentType<P>,
): ComponentType<Omit<P, "theme">> {
  const WithTheme = (props: Omit<P, "theme">) => {
    const theme = use(ThemeContext);
    return <WrappedComponent {...(props as P)} theme={theme} />;
  };

  WithTheme.displayName = `WithTheme(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return WithTheme;
}

export default ThemeContext;
