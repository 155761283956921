import { RouteObject } from "react-router";
import NotFoundError from "./NotFoundError.ts";

const notFoundErrorThrowingRoute = {
  Component: () => {
    throw new NotFoundError();
  },
} as RouteObject;

export default notFoundErrorThrowingRoute;
