/**
 * @generated SignedSource<<0eaacecb1a00dbd5411ded06f5165d6a>>
 * @relayHash 2ce80615e8cdf353e5a3e1968e2924bc
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2ce80615e8cdf353e5a3e1968e2924bc

import { ConcreteRequest } from 'relay-runtime';
export type UpgradeSubscriptionPlanLinkVenueQuery$variables = {
  id: string;
};
export type UpgradeSubscriptionPlanLinkVenueQuery$data = {
  readonly venue: {
    readonly account: {
      readonly currentUserHasAdministerPermission: boolean;
      readonly id: string;
    };
  } | null | undefined;
};
export type UpgradeSubscriptionPlanLinkVenueQuery = {
  response: UpgradeSubscriptionPlanLinkVenueQuery$data;
  variables: UpgradeSubscriptionPlanLinkVenueQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": "currentUserHasAdministerPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "permission",
          "value": "ADMINISTER"
        }
      ],
      "kind": "ScalarField",
      "name": "currentUserHasPermission",
      "storageKey": "currentUserHasPermission(permission:\"ADMINISTER\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpgradeSubscriptionPlanLinkVenueQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Venue",
        "kind": "LinkedField",
        "name": "venue",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpgradeSubscriptionPlanLinkVenueQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Venue",
        "kind": "LinkedField",
        "name": "venue",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "2ce80615e8cdf353e5a3e1968e2924bc",
    "metadata": {},
    "name": "UpgradeSubscriptionPlanLinkVenueQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "95015e638c3b8712f351110ebdd7632e";

export default node;
