/**
 * @generated SignedSource<<15d78c2c68ce9a9e695e06ed13e315e3>>
 * @relayHash 313ef786d7fc434717598e4ac81e4c0f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 313ef786d7fc434717598e4ac81e4c0f

import { ConcreteRequest } from 'relay-runtime';
export type UpgradeSubscriptionPlanLinknMeQuery$variables = Record<PropertyKey, never>;
export type UpgradeSubscriptionPlanLinknMeQuery$data = {
  readonly me: {
    readonly account: {
      readonly currentUserHasAdministerPermission: boolean;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type UpgradeSubscriptionPlanLinknMeQuery = {
  response: UpgradeSubscriptionPlanLinknMeQuery$data;
  variables: UpgradeSubscriptionPlanLinknMeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": "currentUserHasAdministerPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "permission",
          "value": "ADMINISTER"
        }
      ],
      "kind": "ScalarField",
      "name": "currentUserHasPermission",
      "storageKey": "currentUserHasPermission(permission:\"ADMINISTER\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpgradeSubscriptionPlanLinknMeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpgradeSubscriptionPlanLinknMeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "313ef786d7fc434717598e4ac81e4c0f",
    "metadata": {},
    "name": "UpgradeSubscriptionPlanLinknMeQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "986048aa0a9acd700fa55efae12669bd";

export default node;
