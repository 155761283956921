import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import guestsRoute from "./PermanentGuestListGuests/route.ts";
import settingsRoute from "./PermanentGuestListSettings/route.ts";

export default {
  path: ":permanentGuestListId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./PermanentGuestListRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [
        { index: true, loader: () => redirect("guests") },
        { path: "guests", ...guestsRoute },
        { path: "settings", ...settingsRoute },
      ],
    },
  ],
} as RouteObject;
