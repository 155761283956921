import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./EventAutoMessageDetails/route.ts";
import deleteRoute from "./DeleteEventAutoMessage/route.ts";

export default {
  path: ":autoMessageId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./EventAutoMessageRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
