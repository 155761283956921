export type Options = {
  userFriendlyMessage?: string;
  cause?: any;
};

export default class UserFriendlyMessageError extends Error {
  userFriendlyMessage?: string;

  cause: any;

  constructor(
    message: string,
    { userFriendlyMessage = message, ...otherProps }: Options = {},
  ) {
    super(message);
    this.userFriendlyMessage = userFriendlyMessage;
    Object.assign(this, otherProps);
  }
}
