import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./VenueDetails/route.ts";
import permissionsRoute from "./VenuePermissions/route.ts";
import deleteRoute from "./DeleteVenue/route.ts";

export default {
  path: ":venueId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./VenueRouteErrorBoundary.tsx")).default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "permissions", ...permissionsRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
