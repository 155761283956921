import AuthorizationError from "./AuthorizationError.ts";
import AuthenticationError from "./AuthenticationError.ts";
import ConnectionFailedError from "./ConnectionFailedError.ts";
import GraphqlError from "./GraphqlError.ts";

export default async function errorMappingFetch(
  url: RequestInfo,
  request?: Parameters<typeof fetch>[1],
): ReturnType<typeof fetch> {
  let response;

  if (
    typeof request?.body === "string" &&
    request.body.length > 1024 &&
    typeof CompressionStream !== "undefined"
  ) {
    const stream = new CompressionStream("gzip");
    const writer = stream.writable.getWriter();
    writer.write(new TextEncoder().encode(request.body));
    writer.close();
    request.body = await new Response(stream.readable).blob();
    request.headers = {
      ...(request.headers || {}),
      "Content-Encoding": "gzip",
    };
  }

  try {
    response = await fetch(url, request);
  } catch (error) {
    throw new ConnectionFailedError({ cause: error, url });
  }

  if (response.ok) return response;

  let errorProps: { [key: string]: string } = {};
  try {
    if (
      response.headers.get("Content-Type")?.toLowerCase() === "application/json"
    )
      errorProps = await response.json();
    else errorProps.userFriendlyMessage = await response.text();
  } catch (error) {}

  const { message, ...errorOptions } = errorProps;

  if (response.status === 401)
    throw new AuthenticationError(message, errorOptions);
  if (response.status === 403)
    throw new AuthorizationError(message, errorOptions);
  throw new GraphqlError(message, errorOptions);
}
