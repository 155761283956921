import { RouteObject } from "react-router";
import homeRoute from "./PagesHome/route.ts";
import newRoute from "./NewPage/route.ts";
import pageRoute from "./Page/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./PagesRouteErrorBoundary.tsx")).default,
      }),
      children: [{ path: "new", ...newRoute }, pageRoute],
    },
  ],
} as RouteObject;
