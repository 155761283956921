import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./EventDetails/route.ts";
import fieldsRoute from "./EventFields/route.ts";
import guestListsRoute from "./EventGuestLists/route.ts";
import autoMessagesRoute from "./EventAutoMessages/route.ts";
import ticketingRoute from "./EventTicketing/route.ts";
import permissionsRoute from "./EventPermissions/route.ts";
import copyRoute from "./CopyEvent/route.ts";
import deleteRoute from "./DeleteEvent/route.ts";

export default {
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./EventSettingsRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "fields", ...fieldsRoute },
        { path: "guest-lists", ...guestListsRoute },
        { path: "auto-messages", ...autoMessagesRoute },
        { path: "ticketing", ...ticketingRoute },
        { path: "permissions", ...permissionsRoute },
        { path: "copy", ...copyRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
