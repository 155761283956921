import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import createCache from "@emotion/cache";
import { RelayEnvironmentProvider } from "react-relay";
import { CacheProvider } from "@emotion/react";
import { BehaviorSubject, skip } from "rxjs";
import { Environment } from "relay-runtime";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import * as stylex from "@stylexjs/stylex";
import LocaleContext, { useCreateLocaleContext } from "./LocaleContext.tsx";
import ThemesContext from "./ThemesContext.tsx";
import ThemeProvider from "./ThemeProvider.tsx";
import DarkModeContext, {
  useCreateDarkModeContext,
} from "./DarkModeContext.ts";
import NotificationsContext, {
  Notification,
  useCreateNotificationsContext,
} from "./NotificationsContext.tsx";
import SecurityContext, {
  useCreateSecurityContext,
} from "./SecurityContext.tsx";
import CookieManagerContext, {
  useCookieManager,
} from "./CookieManagerContext.tsx";
import SafeAreaInsetsContext from "./SafeAreaInsetsContext.tsx";
import AppMeasurementsContext from "./AppMeasurementsContext.tsx";
import BrowserIsNotSupportedContext from "./BrowserIsNotSupportedContext.tsx";
import ScreenRecorder from "./ScreenRecorder/index.ts";
import SharerContext, { Sharer } from "./SharerContext.tsx";
import UserAgentContext from "./UserAgentContext.tsx";
import MobileModeContext, {
  useCreateMobileModeContext,
} from "./MobileModeContext.tsx";
import { useNonce } from "./NonceContext.tsx";
import cookieConfigurations from "./cookieConfigurations.ts";
import LinkHandlerContext, { LinkHandler } from "./LinkHandlerContext.tsx";
import SecurityManager from "./SecurityManager.ts";
import CenteredLoadingIndicator from "./CenteredLoadingIndicator.tsx";
import { useTracking } from "./tracking/TrackingContext.ts";
import useConsent from "./useConsent.tsx";
import { safeAreaInsetVarsCss } from "./css.ts";
import Measured from "./Measured.tsx";
import useTrackingInfo from "./tracking/useTrackingInfo.ts";
import CountryCodeContext from "./CountryCodeContext.ts";
import { useConfig } from "./ConfigContext.ts";
import "global.css";
import addStyle from "./addStyle.ts";

const styles = stylex.create({
  main: { height: "100%" },
});

export default function AppBase({
  relayEnvironmentSubject,
  sharer,
  userAgent,
  defaultMobileMode,
  defaultDarkMode,
  linkHandler: linkHandlerProp,
  securityManager,
  countryCode,
}: {
  relayEnvironmentSubject: BehaviorSubject<Environment>;
  sharer?: Sharer;
  userAgent?: string;
  defaultMobileMode?: boolean;
  defaultDarkMode?: boolean;
  linkHandler?: LinkHandler;
  securityManager: SecurityManager;
  countryCode: string;
}) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const cookieManager = useCookieManager();
  const previousNotificationsRef = useRef<Notification[]>(null);
  const screenRecorderRef = useRef<ScreenRecorder>(null);
  const darkMode = useCreateDarkModeContext({ defaultDarkMode });
  const mobileMode = useCreateMobileModeContext({ defaultMobileMode });
  const locale = useCreateLocaleContext();
  const security = useCreateSecurityContext({ securityManager });
  const notifications = useCreateNotificationsContext();
  const nonce = useNonce();
  const [consent] = useConsent();
  const tracking = useTracking();
  const trackingInfo = useTrackingInfo();
  const config = useConfig();

  const [relayEnvironment, setRelayEnvironment] = useState(
    relayEnvironmentSubject.getValue(),
  );
  useEffect(() => {
    const subscriber = relayEnvironmentSubject
      .pipe(skip(1))
      .subscribe(setRelayEnvironment);
    return () => subscriber.unsubscribe();
  }, [relayEnvironmentSubject]);

  useEffect(() => {
    tracking.init({ consent });

    if (ScreenRecorder.isSupported()) {
      const screenRecorder = new ScreenRecorder({ relayEnvironment });
      screenRecorder.init();
      screenRecorderRef.current = screenRecorder;

      return () => {
        screenRecorder?.stop();
        darkMode.destroy?.();
      };
    }
    return undefined;
  }, []);

  useEffect(() => {
    tracking.setConsent(consent);
  }, [consent]);

  useEffect(() => screenRecorderRef.current?.setConfig(config), [config]);
  useEffect(
    () => screenRecorderRef.current?.setAuth(security?.auth),
    [security?.auth],
  );
  useEffect(() => {
    screenRecorderRef.current?.setTrackingInfo(trackingInfo);
  }, [trackingInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (previousNotificationsRef.current)
      notifications.dismissNotifications(
        previousNotificationsRef.current.filter((n) => !n.autoHide),
      );
    previousNotificationsRef.current = notifications.notifications;
  }, [location.pathname]);

  useEffect(() => {
    tracking.setLocation({ location, params });
  }, [location]);

  const linkHandler = useMemo(
    () =>
      linkHandlerProp || {
        navigate: ({ to, target }) => {
          if (target && target !== "_self") window.open(to, target);
          else if (to.startsWith("http://") || to.startsWith("https://"))
            window.location.href = to;
          else navigate(to);
        },
      },
    [linkHandlerProp, navigate],
  );

  const emotionCache = useMemo(() => createCache({ key: "a", nonce }), [nonce]);

  const browserIsNotSupported = cookieManager.get(
    cookieConfigurations.browserIsNotSupported,
  );

  return (
    <CountryCodeContext value={countryCode}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <CacheProvider value={emotionCache}>
          <MobileModeContext value={mobileMode}>
            <SharerContext value={sharer}>
              <CookieManagerContext value={cookieManager}>
                <BrowserIsNotSupportedContext value={browserIsNotSupported}>
                  <LocaleContext value={locale}>
                    <LinkHandlerContext value={linkHandler}>
                      <SecurityContext value={security}>
                        <NotificationsContext value={notifications}>
                          <UserAgentContext value={userAgent}>
                            <DarkModeContext value={darkMode}>
                              <ThemesContext.Provider>
                                <ThemeProvider dark={darkMode.darkMode}>
                                  <ScrollRestoration />

                                  <meta charSet="UTF-8" />
                                  <meta
                                    httpEquiv="X-UA-Compatible"
                                    content="IE=edge,chrome=1"
                                  />
                                  <meta
                                    name="viewport"
                                    content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, viewport-fit=cover"
                                  />
                                  <meta name="referrer" content="origin" />
                                  <meta
                                    name="apple-mobile-web-app-capable"
                                    content="yes"
                                  />
                                  <meta
                                    name="mobile-web-app-capable"
                                    content="yes"
                                  />
                                  <meta
                                    name="apple-mobile-web-app-status-bar-style"
                                    content="black-translucent"
                                  />
                                  <meta
                                    name="msapplication-tap-highlight"
                                    content="no"
                                  />
                                  <link rel="manifest" href="/manifest.json" />
                                  <link rel="icon" href="/favicon.ico" />
                                  <link
                                    rel="icon"
                                    type="image/svg+xml"
                                    href="/images/favicon.svg"
                                  />

                                  <Measured width height safeAreaInsets>
                                    {({
                                      ref: measureRef,
                                      ...appMeasurements
                                    }) => (
                                      <div
                                        ref={measureRef}
                                        {...addStyle(
                                          stylex.props(styles.main),
                                          appMeasurements.safeAreaInsets &&
                                            safeAreaInsetVarsCss(
                                              appMeasurements.safeAreaInsets,
                                            ),
                                        )}
                                      >
                                        <AppMeasurementsContext
                                          value={appMeasurements}
                                        >
                                          <SafeAreaInsetsContext
                                            value={
                                              appMeasurements.safeAreaInsets
                                            }
                                          >
                                            <Suspense
                                              fallback={
                                                <CenteredLoadingIndicator />
                                              }
                                            >
                                              <Outlet />
                                            </Suspense>
                                          </SafeAreaInsetsContext>
                                        </AppMeasurementsContext>
                                      </div>
                                    )}
                                  </Measured>
                                </ThemeProvider>
                              </ThemesContext.Provider>
                            </DarkModeContext>
                          </UserAgentContext>
                        </NotificationsContext>
                      </SecurityContext>
                    </LinkHandlerContext>
                  </LocaleContext>
                </BrowserIsNotSupportedContext>
              </CookieManagerContext>
            </SharerContext>
          </MobileModeContext>
        </CacheProvider>
      </RelayEnvironmentProvider>
    </CountryCodeContext>
  );
}
