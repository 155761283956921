import { createContext, use } from "react";
import { matchRoutes } from "react-router";

const RoutePrefetcherContext =
  createContext<(route: Parameters<typeof matchRoutes>[1]) => Promise<void>>(
    null,
  );

export const useRoutePrefetcher = () => use(RoutePrefetcherContext);

export default RoutePrefetcherContext;
