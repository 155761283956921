import { createContext, use } from "react";

export enum VirtualKeyboardStyle {
  Dark,
  Light,
}

export type VirtualKeyboardContextValue = {
  futureHeight?: number;
  height?: number;
  setStyle: (style: VirtualKeyboardStyle) => void;
};

const VirtualKeyboardContext = createContext<VirtualKeyboardContextValue>({
  setStyle: () => {},
});

export const useVirtualKeyboard = () => use(VirtualKeyboardContext);

export default VirtualKeyboardContext;
