import { createContext, use } from "react";

export type LinkHandler = {
  navigate: (options: { to: string; target?: string }) => void;
};

const LinkHandlerContext = createContext<LinkHandler>(undefined);

export const useLinkHandler = () => use(LinkHandlerContext);

export default LinkHandlerContext;
