import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { RouteObject, redirect } from "react-router";
import detailsRoute from "./ProductDetails/route.ts";
import deleteRoute from "./DeleteProduct/route.ts";

export default {
  path: ":productId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./ProductRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
