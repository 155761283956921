import { HTMLAttributes, Ref } from "react";
import { mergeRefs } from "react-merge-refs";
import Measured from "./Measured.tsx";
import { SafeAreaInsets } from "./safeAreaInsets.ts";

/**
 * Don't use variable padding in the css of this component (wrap something instead). Because:
 * "It's important to note that while ResizeObserver reports both the dimensions of the contentRect and the padding, it only watches the contentRect." (https://web.dev/resize-observer/)
 */

export default function MeasuredDiv({
  width,
  height,
  top,
  right,
  bottom,
  left,
  safeAreaInsets,
  children,
  onChange,
  elementRef,
  ...otherProps
}: {
  width?: boolean;
  height?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  safeAreaInsets?: boolean;
  children:
    | ((props: {
        width?: number;
        height?: number;
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
        safeAreaInsets?: SafeAreaInsets;
      }) => any)
    | any;
  onChange?: (props: {
    width?: number;
    height?: number;
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  }) => void;
  elementRef?: Ref<HTMLDivElement | undefined>;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <Measured
      width={width}
      height={height}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      safeAreaInsets={safeAreaInsets}
      onChange={onChange}
    >
      {({ ref: measureRef, ...measuredProps }) => (
        <div ref={mergeRefs([elementRef, measureRef])} {...otherProps}>
          {typeof children === "function" ? children(measuredProps) : children}
        </div>
      )}
    </Measured>
  );
}
