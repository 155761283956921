import GraphId, { GraphIdType } from "common/GraphId.ts";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useTranslation } from "react-i18next";
import Link from "common/Link.tsx";
import { UpgradeSubscriptionPlanLinkAccountQuery } from "relay/UpgradeSubscriptionPlanLinkAccountQuery.graphql.ts";
import { ReactNode } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment UpgradeSubscriptionPlanLink_account on Account {
    id
    currentUserHasAdministerPermission: currentUserHasPermission(
      permission: ADMINISTER
    )
  }
`;

export default function UpgradeSubscriptionPlanLink({
  targetId,
  missingPermissionLabel,
}: {
  targetId: string;
  missingPermissionLabel: ReactNode;
}) {
  const [t] = useTranslation();
  const targetType = targetId && GraphId.fromString(targetId).type;

  const data = useLazyLoadQuery<UpgradeSubscriptionPlanLinkAccountQuery>(
    (targetType === GraphIdType.Account &&
      graphql`
        query UpgradeSubscriptionPlanLinkAccountQuery($id: ID!) {
          account(id: $id) {
            ...UpgradeSubscriptionPlanLink_account @relay(mask: false)
          }
        }
      `) ||
      (targetType === GraphIdType.Venue &&
        graphql`
          query UpgradeSubscriptionPlanLinkVenueQuery($id: ID!) {
            venue(id: $id) {
              account {
                ...UpgradeSubscriptionPlanLink_account @relay(mask: false)
              }
            }
          }
        `) ||
      (targetType === GraphIdType.Event &&
        graphql`
          query UpgradeSubscriptionPlanLinkEventQuery($id: ID!) {
            event(id: $id) {
              venue {
                account {
                  ...UpgradeSubscriptionPlanLink_account @relay(mask: false)
                }
              }
            }
          }
        `) ||
      graphql`
        query UpgradeSubscriptionPlanLinknMeQuery {
          me {
            account {
              ...UpgradeSubscriptionPlanLink_account @relay(mask: false)
            }
          }
        }
      `,
    { id: targetId },
  );

  const { id: accountId, currentUserHasAdministerPermission } = (
    data.event?.venue ||
    data.venue ||
    data.me ||
    data
  ).account;

  return currentUserHasAdministerPermission ? (
    <Link
      to={`/manager/${`accounts/${encodeURIComponent(accountId)}`}/subscription`}
    >
      {t("UpgradeSubscriptionPlanLink.label")}
    </Link>
  ) : (
    missingPermissionLabel ||
      t("UpgradeSubscriptionPlanLink.missingPermissionLabel")
  );
}
