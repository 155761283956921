import { RouteObject } from "react-router";

export default async function getLazyRouteProps(
  modulePromise: Promise<{
    default?: RouteObject["Component"];
    errorElement?: RouteObject["errorElement"];
    ErrorBoundary?: RouteObject["ErrorBoundary"];
    shouldRevalidate?: RouteObject["shouldRevalidate"];
  }>,
): ReturnType<RouteObject["lazy"]> {
  const {
    default: Component,
    errorElement,
    ErrorBoundary,
  } = await modulePromise;

  const route: any = {};

  if (Component) route.Component = Component;
  if (errorElement) route.errorElement = errorElement;
  if (ErrorBoundary) route.ErrorBoundary = ErrorBoundary;

  return route;
}
