import * as stylex from "@stylexjs/stylex";
import { useEffect, useState } from "react";
import { noteCss } from "./css.ts";
import Svg from "./Svg.tsx";

const styles = stylex.create({
  main: (size) => ({
    textAlign: "center",
    width: size,
    height: size,
  }),
  hiddenMain: {
    visibility: "hidden",
  },
});

export default function LoadingIndicator({
  showDelay = 1000,
  color,
  size = 40,
  progress,
  description,
  style,
}: {
  showDelay?: number;
  color?: string;
  size?: number;
  progress?: number;
  description?: string;
  style?: stylex.StyleXStyles;
}) {
  const [show, setShow] = useState(!showDelay);

  useEffect(() => {
    if (showDelay) {
      const ref = setTimeout(() => setShow(true), showDelay);
      return () => clearTimeout(ref);
    }
    return undefined;
  }, []);

  return (
    <div
      {...stylex.props(styles.main(size), !show && styles.hiddenMain, style)}
    >
      <Svg
        width={size}
        height={size}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke={color || "var(--separator-color)"}
          strokeWidth="10"
          r="40"
          strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(17.769 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </Svg>
      {(progress !== undefined || description) && (
        <div css={{ ...noteCss, whiteSpace: "nowrap" }}>
          {[
            description,
            progress !== undefined && `${Math.floor(progress * 100)}%`,
          ]
            .filter((p) => p)
            .join(": ")}
        </div>
      )}
    </div>
  );
}
