import { createContext, use, useEffect, useState } from "react";
import SecurityManager from "./SecurityManager.ts";
import Auth from "./Auth.ts";

export type SecurityContextValue = {
  auth?: Auth;
  logOut: () => void;
  logIn: SecurityManager["logIn"];
};

const SecurityContext = createContext<SecurityContextValue>(null);

export const useSecurity = () => use(SecurityContext);

export function useCreateSecurityContext({
  securityManager,
}: {
  securityManager: SecurityManager;
}): SecurityContextValue {
  const [value, setValue] = useState<SecurityContextValue>(() => ({
    auth: securityManager.getAuth(),
    logIn: (...args: Parameters<SecurityManager["logIn"]>) =>
      securityManager.logIn(...args),
    logOut: (...args: Parameters<SecurityManager["logOut"]>) =>
      securityManager.logOut(...args),
  }));

  useEffect(() => {
    const listener = (auth: Auth) =>
      setValue((securityContext) => ({ ...securityContext, auth }));
    const subscription = securityManager.subscribeToAuth(listener);
    return () => subscription.unsubscribe();
  }, []);

  return value;
}

export default SecurityContext;
