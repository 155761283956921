import { RouteObject } from "react-router";
import homeRoute from "./IndustriesHome/route.ts";
import newRoute from "./NewIndustry/route.ts";
import industryRoute from "./Industry/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./IndustriesRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [{ path: "new", ...newRoute }, industryRoute],
    },
  ],
} as RouteObject;
