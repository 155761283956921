import { createContext, use } from "react";
import useCookieState from "./useCookieState.tsx";
import cookieConfigurations from "./cookieConfigurations.ts";

export type MobileModeContextValue = boolean;

const MobileModeContext = createContext<MobileModeContextValue>(false);

export const useMobileMode = () => use(MobileModeContext);

export function useCreateMobileModeContext({
  defaultMobileMode = false,
}: {
  defaultMobileMode?: boolean;
}) {
  return (
    useCookieState(cookieConfigurations.mobileMode)[0] ?? defaultMobileMode
  );
}

export default MobileModeContext;
