import color from "color";
import {
  Fragment,
  ReactElement,
  ReactNode,
  Ref,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useAppMeasurements } from "./AppMeasurementsContext.tsx";
import Button from "./Button.tsx";
import CenteredLoadingIndicator from "./CenteredLoadingIndicator.tsx";
import {
  commonStyles,
  mainCss,
  roundedBorderedCss,
  safeAreaInsetVarsCss,
  themeVarsCss,
} from "./css.ts";
import Focuser from "./Focuser.tsx";
import CloseIcon from "./icons/CloseIcon.tsx";
import MeasuredDiv from "./MeasuredDiv.tsx";
import { SafeAreaInsets } from "./safeAreaInsets.ts";
import SafeAreaInsetsContext from "./SafeAreaInsetsContext.tsx";
import { useTheme } from "./ThemeContext.tsx";
import { useVirtualKeyboard } from "./VirtualKeyboardContext.tsx";

export type HoverContent = (props: {
  fullscreen: boolean;
  safeAreaInsets: SafeAreaInsets;
  toggle: () => void;
  hide: () => void;
  maxHeight?: number;
}) => ReactNode;

const zeroSafeAreaInsets = { top: 0, right: 0, bottom: 0, left: 0 };

function getElement() {
  const element = document.getElementById("hoverBoxContents");
  if (!element)
    throw new Error(
      "HoverBox root element with id hoverBoxContents doesn't exist.",
    );
  return element;
}

export default function HoverBox({
  children,
  hoverContent,
  hoverContentKey,
  onHoverContentDidHide,
  hoverContentMaxWidth,
  defaultShowingHoverContent,
  keepHoverContentOnBlur,
}: {
  children: (props: {
    ref: Ref<any>;
    toggleHoverContent: () => void;
    showHoverContent: () => void;
    hideHoverContent: () => void;
    onBlur?: () => void;
    showingHoverContent: boolean;
  }) => ReactElement;
  hoverContent: HoverContent;
  hoverContentKey?: object;
  onHoverContentDidHide?: () => void;
  hoverContentMaxWidth?: number;
  defaultShowingHoverContent?: boolean;
  keepHoverContentOnBlur?: boolean;
}) {
  const appMeasurements = useAppMeasurements();
  const virtualKeyboard = useVirtualKeyboard();
  const theme = useTheme();
  const childrenRef = useRef<HTMLElement>(null);
  const hoverContentRef = useRef<HTMLDivElement>(null);
  const hoverContentFocuserRef = useRef<typeof Focuser>(null);
  const [showingHoverContent, setShowingHoverContent] = useState(
    defaultShowingHoverContent,
  );
  const [hoverContentCoordinates, setHoverContentCoordinates] = useState<{
    top?: number;
    bottom?: number;
    left?: number;
  }>();
  const [fullscreenHoverContent, setFullscreenHoverContent] =
    useState<boolean>();
  const [hoverContentMaxHeight, setHoverContentMaxHeight] = useState<number>();
  const [bottomHeight, setBottomHeight] = useState<number>();

  const updateHoverContent = useCallback(() => {
    if (
      !showingHoverContent ||
      !childrenRef.current ||
      !hoverContentRef.current ||
      !appMeasurements?.safeAreaInsets
    )
      return;

    const childrenRect = childrenRef.current.getBoundingClientRect();
    const spaceTop = childrenRect.top - appMeasurements.safeAreaInsets.top;
    const spaceLeft = childrenRect.left;
    const spaceRight = window.innerWidth - spaceLeft;
    const spaceBottom =
      window.innerHeight -
      childrenRect.bottom -
      Math.max(
        appMeasurements.safeAreaInsets.bottom,
        virtualKeyboard?.height || 0,
        virtualKeyboard?.futureHeight || 0,
      );
    const positionAbove = spaceTop > spaceBottom;

    setHoverContentMaxHeight((positionAbove ? spaceTop : spaceBottom) - 2); // Adjust for border

    if (window.innerHeight < 500 || window.innerWidth < 500) {
      setHoverContentCoordinates(null);
      setFullscreenHoverContent(true);
    } else {
      const hoverContentRect = hoverContentRef.current.getBoundingClientRect();
      const hoverContentMarginRight = spaceRight - hoverContentRect.width;
      setHoverContentCoordinates({
        top: positionAbove ? undefined : childrenRect.bottom,
        bottom: positionAbove
          ? window.innerHeight - childrenRect.top
          : undefined,
        left:
          hoverContentMarginRight < theme.paddingRight
            ? Math.max(
                theme.paddingLeft,
                childrenRect.left +
                  hoverContentMarginRight -
                  theme.paddingRight,
              )
            : childrenRect.left,
      });
    }
  }, [showingHoverContent, appMeasurements, hoverContent]);

  const onHoverContentDidShow = useCallback(() => {
    if (
      document.activeElement &&
      !hoverContentRef.current?.contains(document.activeElement)
    )
      hoverContentRef.current?.focus();
    hoverContentFocuserRef.current?.autoFocus();
  }, []);

  const toggleHoverContent = useCallback(
    () => setShowingHoverContent((old) => !old),
    [],
  );
  const showHoverContent = useCallback(() => setShowingHoverContent(true), []);
  const hideHoverContent = useCallback(() => setShowingHoverContent(false), []);

  const onBlur = useCallback((event: FocusEvent) => {
    // Children must be focusable, for example using tabindex=0. Otherwise event.relatedTarget will be null
    if (
      event.relatedTarget &&
      !keepHoverContentOnBlur &&
      !getElement()?.contains(event.relatedTarget) &&
      !childrenRef.current?.contains(event.relatedTarget)
    )
      hideHoverContent();
  }, []);

  const onBottomMeasuredChange = useCallback(
    ({ height }: { height: number }) => setBottomHeight(height),
    [],
  );

  const setHoverContentRef = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) updateHoverContent();
      hoverContentRef.current = ref;
    },
    [updateHoverContent],
  );

  const setChildrenRef = useCallback(
    (ref: HTMLElement | null) => {
      childrenRef.current?.removeEventListener("blur", onBlur);
      if (ref) {
        ref.addEventListener("blur", onBlur);
        updateHoverContent();
      }
      childrenRef.current = ref;
    },
    [updateHoverContent],
  );

  useEffect(updateHoverContent, [appMeasurements, hoverContentKey]);

  const wasShowingHoverContentRef = useRef(showingHoverContent);
  useEffect(() => {
    const didHide = wasShowingHoverContentRef.current && !showingHoverContent;
    wasShowingHoverContentRef.current = showingHoverContent;

    if (didHide) onHoverContentDidHide?.();

    if (showingHoverContent) {
      updateHoverContent();
      onHoverContentDidShow();

      if (!keepHoverContentOnBlur) {
        const listener = (event: MouseEvent | TouchEvent) => {
          if (
            childrenRef.current?.contains(event.target) === false &&
            getElement()?.contains(event.target) === false
          )
            hideHoverContent();
        };
        document.addEventListener("mousedown", listener, true);
        return () => document.removeEventListener("mousedown", listener, true);
      }
    }
  }, [showingHoverContent]);

  return (
    <Fragment>
      {children({
        ref: setChildrenRef,
        toggleHoverContent,
        showHoverContent,
        hideHoverContent,
        showingHoverContent: !!showingHoverContent,
      })}

      {hoverContent &&
        showingHoverContent &&
        typeof window !== "undefined" &&
        ReactDOM.createPortal(
          (() => {
            let actualHoverContent = null;
            let hoverContentSafeAreaInsets: SafeAreaInsets;

            if (!fullscreenHoverContent || bottomHeight !== undefined) {
              if (fullscreenHoverContent) {
                hoverContentSafeAreaInsets = {
                  ...appMeasurements.safeAreaInsets,
                  bottom: Math.max(
                    appMeasurements.safeAreaInsets.bottom + (bottomHeight || 0),
                    (virtualKeyboard?.futureHeight === undefined
                      ? virtualKeyboard?.height
                      : virtualKeyboard?.futureHeight) || 0,
                  ),
                };
                if (hoverContentMaxWidth !== undefined) {
                  const leftRightSpace =
                    (appMeasurements.width - hoverContentMaxWidth) / 2;
                  if (leftRightSpace > 0) {
                    hoverContentSafeAreaInsets.left = Math.max(
                      0,
                      hoverContentSafeAreaInsets.left - leftRightSpace,
                    );
                    hoverContentSafeAreaInsets.right = Math.max(
                      0,
                      hoverContentSafeAreaInsets.right - leftRightSpace,
                    );
                  }
                }
              } else hoverContentSafeAreaInsets = zeroSafeAreaInsets;

              actualHoverContent = (
                <Suspense
                  fallback={
                    <CenteredLoadingIndicator style={commonStyles.padded} />
                  }
                >
                  <Focuser ref={hoverContentFocuserRef}>
                    <SafeAreaInsetsContext value={hoverContentSafeAreaInsets}>
                      {hoverContent({
                        fullscreen: !!fullscreenHoverContent,
                        toggle: toggleHoverContent,
                        hide: hideHoverContent,
                        safeAreaInsets: hoverContentSafeAreaInsets,
                        maxHeight: fullscreenHoverContent
                          ? appMeasurements.height
                          : hoverContentMaxHeight,
                      })}
                    </SafeAreaInsetsContext>
                  </Focuser>
                </Suspense>
              );
            }

            return (
              <div
                ref={setHoverContentRef}
                tabIndex={0}
                onBlur={onBlur}
                css={{
                  ...themeVarsCss(theme),
                  ...safeAreaInsetVarsCss(hoverContentSafeAreaInsets),
                  ...mainCss,
                  ...(fullscreenHoverContent ? {} : roundedBorderedCss()),
                  background: fullscreenHoverContent
                    ? `rgba(${color(theme.backgroundColor)
                        .rgb()
                        .array()
                        .map((component) => Math.round(component))
                        .join(",")},0.95)`
                    : "var(--background-color)",
                  "@supports(backdrop-filter: blur(10px))": {
                    backdropFilter: fullscreenHoverContent
                      ? "blur(10px)"
                      : null,
                    background: fullscreenHoverContent
                      ? `rgba(${color(theme.backgroundColor)
                          .rgb()
                          .array()
                          .map((component) => Math.round(component))
                          .join(",")},0.7)`
                      : "var(--background-color)",
                  },
                  boxSizing: "border-box",
                  position: "fixed",
                  top: fullscreenHoverContent
                    ? 0
                    : hoverContentCoordinates?.top,
                  right: fullscreenHoverContent ? 0 : null,
                  bottom: fullscreenHoverContent
                    ? 0
                    : (hoverContentCoordinates?.bottom &&
                        virtualKeyboard &&
                        Math.max(
                          virtualKeyboard?.height + theme.paddingBottom,
                          hoverContentCoordinates.bottom,
                        )) ||
                      hoverContentCoordinates?.bottom,
                  left: fullscreenHoverContent
                    ? 0
                    : hoverContentCoordinates?.left,
                  width:
                    hoverContentMaxWidth && !fullscreenHoverContent
                      ? "100%"
                      : null,
                  maxWidth: fullscreenHoverContent
                    ? null
                    : hoverContentMaxWidth,
                  maxHeight: fullscreenHoverContent
                    ? null
                    : hoverContentMaxHeight,
                  visibility:
                    !fullscreenHoverContent &&
                    hoverContentCoordinates?.top === undefined &&
                    hoverContentCoordinates?.bottom === undefined
                      ? "hidden"
                      : null,
                  zIndex: 10,
                  overflow: "auto",
                  display: "flex",
                }}
              >
                {actualHoverContent}

                {fullscreenHoverContent && (
                  <Fragment>
                    <style>
                      {`
                        html {
                          width: 100%;
                          height: 100%;
                          position: fixed; /* Disable bouncy scroll */
                        }

                        body, #content {
                          height: 100%;
                          overflow: hidden;
                        }
                    `}
                    </style>
                    {bottomHeight && (
                      <div
                        css={{
                          position: "fixed",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height:
                            bottomHeight +
                            (appMeasurements.safeAreaInsets.bottom || 0),
                          pointerEvents: "none",
                          background: `linear-gradient(rgba(${color(
                            theme.backgroundColor,
                          )
                            .rgb()
                            .array()
                            .map(Math.round)
                            .join(",")},0), var(--background-color))`,
                        }}
                      />
                    )}
                    <MeasuredDiv
                      height
                      onChange={onBottomMeasuredChange}
                      css={{
                        position: "fixed",
                        paddingBottom: "var(--padding-bottom)",
                        bottom: appMeasurements.safeAreaInsets.bottom || 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <Button variant="naked" onClick={hideHoverContent}>
                        <div
                          css={{
                            ...roundedBorderedCss({ radius: "50%" }),
                            padding: "var(--spacing)",
                            background: "var(--background-color)",
                          }}
                        >
                          <CloseIcon
                            width={Math.floor(theme.iconSize * 1.6)}
                            color="var(--note-color)"
                            css={{ display: "block" }}
                          />
                        </div>
                      </Button>
                    </MeasuredDiv>
                  </Fragment>
                )}
              </div>
            );
          })(),
          getElement(),
        )}
    </Fragment>
  );
}
