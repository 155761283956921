import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { RouteObject } from "react-router";
import homeRoute from "./EventAutoMessagesHome/route.ts";
import newRoute from "./NewEventAutoMessage/route.ts";
import autoMessageRoute from "./EventAutoMessage/route.ts";

export default {
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./EventAutoMessagesRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [{ path: "new", ...newRoute }, autoMessageRoute],
    },
  ],
} as RouteObject;
