import { RouteObject } from "react-router";
import homeRoute from "./ProductsHome/route.ts";
import newRoute from "./NewProduct/route.ts";
import productRoute from "./Product/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./ProductsRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [{ path: "new", ...newRoute }, productRoute],
    },
  ],
} as RouteObject;
