import i18next from "i18next";
import ConnectionFailedError, {
  Options as ParentOptions,
} from "./ConnectionFailedError.ts";

export type Options = ParentOptions;

export default class WebsocketConnectionFailedError extends ConnectionFailedError {
  constructor({
    message = "Websocket connection failed.",
    userFriendlyMessage = i18next.t(
      "WebsocketConnectionFailedError.userFriendlyMessage"
    ),
    ...otherOptions
  }: Options = {}) {
    super({ ...otherOptions, userFriendlyMessage });
    this.name = "WebsocketConnectionFailedError";
  }
}
