import ConsentCategory from "./ConsentCategory.ts";
import CookieContentType from "./CookieContentType.ts";
import Consent from "./Consent.ts";
import cookieConfigurations from "./cookieConfigurations.ts";

export interface Backend {
  nullIsNoItem: boolean;
  get: (props: { name: string; path?: string }) => string | null | undefined;
  set: (props: {
    name: string;
    value: string;
    expires?: Date;
    path?: string;
  }) => void;
  delete: (props: { name: string; path?: string }) => void;
}

type Subscriber = (value: any) => void;

/**
 * Manages cookies based on cookie settings.
 * Caches values and only uses backend when needed, so all cookies in the application should be set using the same manager instance.
 * If a cookie with a category not allowed by the settings is set, it is saved in memory but not in the backend (which should save them to actual cookies) - unless the settings are changed at which point they are flushed to the backend.
 */
export default class CookieManager {
  private values: { [name: string]: any } = {};

  private backend: Backend;

  private subscribers: { [name: string]: Set<Subscriber> } = {};

  private consent?: Consent;

  public constructor(backend: Backend) {
    this.backend = backend;
    this.setConsent(this.get(cookieConfigurations.consent));
  }

  private setConsent(consent: Consent) {
    this.consent = consent;
  }

  public get(props: { name: string; contentType?: CookieContentType }) {
    const { name, contentType } = props;

    if (name in this.values) return this.values[name];

    const value = this.backend.get(props);

    if (
      value !== null &&
      value !== undefined &&
      contentType === CookieContentType.Json
    ) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return undefined;
      }
    } else
      return this.backend.nullIsNoItem && value === null ? undefined : value;
  }

  public set({
    name,
    value,
    contentType,
    ignoreSubscribers,
    maxAgeDays,
    category,
    path = "/",
  }: {
    name: string;
    value: any;
    contentType?: CookieContentType;
    ignoreSubscribers?: Subscriber[];
    maxAgeDays?: number;
    category: ConsentCategory;
    path?: string;
  }): void {
    if (name === cookieConfigurations.consent.name) this.setConsent(value);

    if (value === undefined) this.delete({ name, path });
    else {
      const isAllowedByConsent =
        !category ||
        category === ConsentCategory.Essential ||
        this.consent?.acceptedCategories === null ||
        this.consent?.acceptedCategories?.includes(category);

      if (isAllowedByConsent) {
        this.values[name] = value;
        this.backend.set({
          name,
          value:
            contentType === CookieContentType.Json
              ? JSON.stringify(value)
              : value,
          expires:
            maxAgeDays &&
            new Date(new Date().getTime() + maxAgeDays * 24 * 60 * 60 * 1000),
          path,
        });
      }
    }

    this.subscribers[name]?.forEach((s) => {
      if (!ignoreSubscribers || !ignoreSubscribers.includes(s)) s(value);
    });
  }

  public delete({ name, path = "/" }: { name: string; path?: string }) {
    delete this.values[name];
    this.backend.delete({ name, path });
    this.subscribers[name]?.forEach((s) => s(undefined));
  }

  public subscribe({
    name,
    subscriber,
  }: {
    name: string;
    subscriber: Subscriber;
  }) {
    let set = this.subscribers[name];
    if (!set) {
      set = new Set();
      this.subscribers[name] = set;
    }
    set.add(subscriber);

    return { unsubscribe: () => this.unsubscribe({ name, subscriber }) };
  }

  public unsubscribe({
    name,
    subscriber,
  }: {
    name: string;
    subscriber: Subscriber;
  }) {
    const set = this.subscribers[name];
    if (set) {
      set.delete(subscriber);
      if (set.size === 0) delete this.subscribers[name];
    }
  }
}
