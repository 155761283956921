import { Component, ComponentPropsWithoutRef, ErrorInfo } from "react";
import ErrorHandler from "./ErrorHandler.tsx";
import logger from "./logger.ts";

type State = { error?: any };

export default class ErrorBoundary extends Component<
  Omit<ComponentPropsWithoutRef<typeof ErrorHandler>, "error">,
  State
> {
  state: State = {};

  static getDerivedStateFromError(error: any): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logger.error(error, { extras: errorInfo });
  }

  render() {
    return this.state.error ? (
      <ErrorHandler
        error={this.state.error}
        retry={() => this.setState({ error: null })}
        {...this.props}
      />
    ) : (
      this.props.children
    );
  }
}
