/**
 * @generated SignedSource<<e3a98391eec5e85c5ecb7088eee5fbb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpgradeSubscriptionPlanLink_account$data = {
  readonly currentUserHasAdministerPermission: boolean;
  readonly id: string;
  readonly " $fragmentType": "UpgradeSubscriptionPlanLink_account";
};
export type UpgradeSubscriptionPlanLink_account$key = {
  readonly " $data"?: UpgradeSubscriptionPlanLink_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpgradeSubscriptionPlanLink_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpgradeSubscriptionPlanLink_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "currentUserHasAdministerPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "permission",
          "value": "ADMINISTER"
        }
      ],
      "kind": "ScalarField",
      "name": "currentUserHasPermission",
      "storageKey": "currentUserHasPermission(permission:\"ADMINISTER\")"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "1d4d7bc11561cb18fc848f3d0391f921";

export default node;
