import * as stylex from "@stylexjs/stylex";
import HoverBox, { HoverContent } from "common/HoverBox.tsx";
import Link from "common/Link.tsx";
import LoadingIndicator from "common/LoadingIndicator.tsx";
import { useNotifications } from "common/NotificationsContext.tsx";
import isPromise from "common/isPromise.ts";
import logger from "common/logger.ts";
import omit from "object.omit";
import {
  ComponentType,
  Fragment,
  HTMLAttributes,
  ReactNode,
  Ref,
  useCallback,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";
import { matchPath, useLocation } from "react-router";
import { useTheme } from "./ThemeContext.tsx";
import { commonStyles } from "./css.ts";

enum Variant {
  Primary = "primary",
  Alternative = "alternative",
  Secondary = "secondary",
  Danger = "danger",
  Naked = "naked",
  Tab = "tab",
  Discrete = "discrete",
}

const styles = stylex.create({
  sizeVar: (size) => ({ "--size": size }),
  base: {
    border: 0,
    margin: 0,
    padding: 0,
    fontFamily: "var(--font-family)",
    background: "none",
    boxSizing: "border-box",
    display: "inline-block",
    verticalAlign: "middle", // Removes descender gap below inline-block
    cursor: "pointer",
    borderRadius: "calc(var(--round-corner-border-radius) * var(--size))",
    fontWeight: 400,
    fontSize: "calc(var(--font-size) * var(--size))",
    userSelect: "none",
    lineHeight: "calc(1.2 * var(--size))",
  },
  disabled: {
    cursor: "default",
    filter: "grayscale(1)",
  },
  optionButton: {
    paddingLeft: "calc(var(--spacing) * 1.5)",
    paddingTop: "calc(var(--spacing) / 2)",
    paddingBottom: "calc(var(--spacing) / 2)",
  },
  hoverOptionButton: {
    paddingTop: "calc(var(--spacing) / 2)",
    ":first-child": { paddingTop: "var(--spacing)" },
    paddingRight: "calc(var(--spacing) + var(--safe-area-inset-right))",
    paddingBottom: "calc(var(--spacing) / 2)",
    ":last-child": { paddingBottom: "var(--spacing)" },
    paddingLeft: "calc(var(--spacing) + var(--safe-area-inset-left))",
  },
});

const variantStyles = stylex.create({
  primary: {
    color: "var(--background-color)",
    background: "var(--main-color)",
    paddingTop: "calc(9px * var(--size))",
    paddingRight: "calc(12px * var(--size))",
    paddingBottom: "calc(9px * var(--size))",
    paddingLeft: "calc(12px * var(--size))",
    minHeight: "calc(var(--size) * var(--font-size) * var(--line-height))",
  },
  secondary: {
    color: "var(--note-color)",
    background: "var(--background-color)",
    borderWidth: "calc(1px * var(--size))",
    borderStyle: "solid",
    borderColor: "var(--separator-color)",
    paddingTop: "calc(8px * var(--size))",
    paddingRight: "calc(12px * var(--size))",
    paddingBottom: "calc(8px * var(--size))",
    paddingLeft: "calc(12px * var(--size))",
    minHeight: "calc(var(--size) * var(--font-size) * var(--line-height))",
  },
  alternative: {
    color: "var(--main-color)",
    background: "var(--background-color)",
    borderWidth: "calc(1px * var(--size))",
    borderStyle: "solid",
    borderColor: "currentColor",
    paddingTop: "calc(8px * var(--size))",
    paddingRight: "calc(12px * var(--size))",
    paddingBottom: "calc(8px * var(--size))",
    paddingLeft: "calc(12px * var(--size))",
    minHeight: "calc(var(--size) * var(--font-size) * var(--line-height))",
  },
  danger: {
    color: "var(--background-color)",
    background: "var(--error-color)",
    paddingTop: "calc(9px * var(--size))",
    paddingRight: "calc(12px * var(--size))",
    paddingBottom: "calc(9px * var(--size))",
    paddingLeft: "calc(12px * var(--size))",
    minHeight: "calc(var(--size) * var(--font-size) * var(--line-height))",
  },
  discrete: { color: "var(--note-color)" },
  naked: { color: "var(--main-color)" },
  tab: { color: "var(--note-color)" },
});

const variantDisabledStyles = stylex.create({
  primary: {
    background:
      "color-mix(in srgb, var(--background-color) 60%, var(--main-color) 40%)",
  },
  secondary: {
    color:
      "color-mix(in srgb, var(--background-color) 60%, var(--note-color) 40%)",
    borderColor:
      "color-mix(in srgb, var(--background-color) 60%, var(--separator-color) 40%)",
  },
  alternative: {
    color:
      "color-mix(in srgb, var(--background-color) 60%, var(--main-color) 40%)",
    borderColor:
      "color-mix(in srgb, var(--background-color) 60%, var(--main-color) 40%)",
  },
  danger: {
    background:
      "color-mix(in srgb, var(--background-color) 60%, var(--main-color) 40%)",
  },
  discrete: {
    color:
      "color-mix(in srgb, var(--background-color) 60%, var(--note-color) 40%)",
  },
  naked: {
    color:
      "color-mix(in srgb, var(--background-color) 60%, var(--main-color) 40%)",
  },
  tab: {
    color:
      "color-mix(in srgb, var(--background-color) 60%, var(--note-color) 40%)",
  },
});

const variantActiveStyles = stylex.create({
  discrete: { color: "var(--main-color)" },
  tab: {
    color: "var(--text-color)",
    textDecoration: "underline",
    textDecorationColor: "var(--main-color)",
    textDecorationThickness: 2,
    textUnderlineOffset: 5,
  },
});

const labelStyles = stylex.create({
  base: {
    display: "flex",
    flexWrap: "nowrap",
    gap: 4,
  },
  rightAligned: { marginLeft: "auto" },
  centered: { margin: "auto" },
  directionReversed: { flexDirection: "row-reverse" },
  loading: { visibility: "hidden" },
});

const hoverContentContainer2Styles = stylex.create({
  base: {
    width: "100%",
    paddingTop: "var(--safe-area-inset-top)",
    paddingBottom: "var(--safe-area-inset-bottom)",
    display: "flex",
    flexDirection: "column",
  },
  fullscreen: {
    width: "auto",
    margin: "auto",
  },
});

enum IconPosition {
  Left = "left",
  Right = "right",
}

enum LabelAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

type Props = {
  ref?: Ref<any>;
  children?: ReactNode | ((options: { color: string }) => ReactNode);
  size?: number | "small";
  target?: string;
  variant?: Variant[keyof Variant];
  disabled?: boolean;
  loading?: boolean;
  to?: string;
  active?: boolean;
  activeWhenMatch?: boolean;
  activeWhenExactMatch?: boolean;
  icon?: any;
  iconComponent?: ComponentType<any>;
  iconPosition?: Variant[keyof IconPosition];
  onClick?: (event: MouseEvent) => Promise<any> | void;
  onMouseDown?: ((event: MouseEvent) => Promise<any> | void) | boolean;
  hoverContent?: HoverContent;
  hoverContentMaxWidth?: number;
  hoverOptions?: Props[];
  hoverBoxRef?: typeof HoverBox;
  labelAlign?: LabelAlign[keyof LabelAlign];
  options?: Props[];
  stopEventPropagation?: boolean;
  style?: stylex.StyleXStyles;
  type?: HTMLButtonElement["type"];
} & Omit<HTMLAttributes<HTMLDivElement>, "onMouseDown" | "className" | "style">;

export function getKey({ children, to }: Props): string {
  if (children !== undefined) return `${children}`;
  if (to !== undefined) return JSON.stringify(to);
  return "";
}

export default function Button(props: Props) {
  const {
    ref,
    activeWhenMatch,
    activeWhenExactMatch,
    to,
    variant = Variant.Primary,
    children,
    size: propsSize = 1,
    loading: propsLoading,
    icon,
    iconComponent: IconComponent,
    iconPosition = IconPosition.Left,
    labelAlign = LabelAlign.Center,
    active: propsActive,
    onClick,
    onMouseDown,
    disabled,
    options,
    stopEventPropagation,
    style: styleProp,
    type = "button",
    ...otherProps
  } = props;

  const theme = useTheme();
  const [stateLoading, setStateLoading] = useState(false);
  const [dataTestLoadCount, setDataTestLoadCount] = useState(0);
  const [hoverOptionsKeyPath, setHoverOptionsKeyPath] = useState<string[]>([]);
  const location = useLocation();
  const notifications = useNotifications();
  const loading = propsLoading || stateLoading;
  const size =
    propsSize === "small" ? theme.smallFontSize / theme.fontSize : propsSize; // So that the "small" size makes the button text same size as theme.smallFontSize

  const [showOptions, setShowOptions] = useState(
    (to &&
      (activeWhenMatch || activeWhenExactMatch) &&
      matchPath(to, location.pathname)) ||
      options?.some(
        (option) =>
          (option.activeWhenMatch || option.activeWhenExactMatch) &&
          option.to &&
          matchPath(option.to, location.pathname),
      ),
  );

  // Since react by default adds passive event listeners, and only active touch events support preventDefault(). Also react seems to trigger onMouseDown on an element that appears under the mouse even if that element wasn't there when the press started
  const innerRefCleanupRef = useRef<() => void>(null);
  const innerRef = useCallback(
    (actualRef: HTMLElement) => {
      if (
        (onClick || typeof onMouseDown === "function" || options) &&
        !loading
      ) {
        if (innerRefCleanupRef.current) {
          innerRefCleanupRef.current();
          innerRefCleanupRef.current = undefined;
        }

        if (actualRef) {
          const doAction = async (event: MouseEvent | TouchEvent) => {
            event.preventDefault();
            if (stopEventPropagation) event.stopPropagation();
            if (disabled) return;

            try {
              if (options) setShowOptions((show: boolean) => !show);
              else {
                const retVal =
                  (onClick && onClick(event)) ||
                  (typeof onMouseDown === "function" && onMouseDown(event)) ||
                  undefined;
                if (isPromise(retVal)) {
                  setStateLoading(true);
                  await retVal;
                }
              }
            } catch (error) {
              notifications.addNotification({
                error: logger.error(error),
              });
            } finally {
              setStateLoading(false);
              setDataTestLoadCount((old) => old + 1);
            }
          };

          if (onMouseDown) {
            actualRef.addEventListener("touchstart", doAction, {
              passive: false,
            });
            actualRef.addEventListener("mousedown", doAction);
          } else actualRef.addEventListener("click", doAction);

          innerRefCleanupRef.current = () => {
            actualRef.removeEventListener("touchstart", doAction);
            actualRef.removeEventListener("mousedown", doAction);
            actualRef.removeEventListener("click", doAction);
          };
        }
      }
      return undefined;
    },
    [onClick, onMouseDown, loading, disabled],
  );

  // Hover stuff
  let actualHoverContent: HoverContent;
  let actualHoverContentMaxWidth;
  {
    const {
      hoverContent,
      hoverOptions,
      hoverBoxRef,
      hoverContentMaxWidth,
      ...passDownProps
    } = props;

    actualHoverContent = hoverContent;
    actualHoverContentMaxWidth = hoverContentMaxWidth;

    if (hoverOptions) {
      const hoverOption = hoverOptionsKeyPath.reduce(
        (accumulator, key) =>
          accumulator.hoverOptions?.find((option) => getKey(option) === key),
        { hoverOptions },
      );

      actualHoverContent =
        hoverOption.hoverContent ||
        (({ fullscreen, hide }) => (
          <div
            stylex={{
              width: "100%",
              height: "100%",
              display: "flex",
              overflow: "auto",
            }}
          >
            <div
              {...stylex.props(
                hoverContentContainer2Styles.base,
                fullscreen && hoverContentContainer2Styles.fullscreen,
              )}
            >
              {hoverOption.hoverOptions.map((option) => {
                const {
                  hoverContent: optionHoverContent,
                  hoverOptions: optionHoverOptions,
                  onClick: optionOnClick,
                  options: optionOptions,
                  ...otherButtonProps
                } = option;

                const key = getKey(option);

                return (
                  <Button
                    key={key}
                    style={styles.hoverOptionButton}
                    labelAlign={LabelAlign.Left}
                    variant={Variant.Discrete}
                    onClick={async () => {
                      if (optionHoverContent || optionHoverOptions)
                        setHoverOptionsKeyPath([...hoverOptionsKeyPath, key]);
                      if (optionOnClick) await optionOnClick();
                      if (otherButtonProps.to) hide(); // Changing the URL should close the hover content
                    }}
                    options={optionOptions}
                    {...otherButtonProps}
                  />
                );
              })}
            </div>
          </div>
        ));

      if (hoverOption.hoverContentMaxWidth !== undefined)
        actualHoverContentMaxWidth = hoverOption.hoverContentMaxWidth;
    }

    if (actualHoverContent)
      return (
        <HoverBox
          hoverBoxRef={hoverBoxRef}
          hoverContent={actualHoverContent}
          hoverContentKey={hoverOptionsKeyPath}
          hoverContentMaxWidth={actualHoverContentMaxWidth}
          onHoverContentDidHide={() => setHoverOptionsKeyPath([])}
        >
          {({ ref: hoverBoxRef, toggleHoverContent }) => (
            <Button
              {...passDownProps}
              ref={ref ? mergeRefs([hoverBoxRef, ref]) : hoverBoxRef}
              onClick={
                onClick
                  ? () => {
                      toggleHoverContent();
                      onClick();
                    }
                  : toggleHoverContent
              }
            />
          )}
        </HoverBox>
      );
  }

  const matchActive =
    to &&
    (activeWhenMatch || activeWhenExactMatch) &&
    matchPath(to, location.pathname);
  const active = propsActive || matchActive;

  const actualIcon =
    icon ||
    (IconComponent && (
      <IconComponent height={theme.iconSize * size} color="currentColor" />
    ));
  const actualChildren =
    typeof children === "function"
      ? children({ color: null /* TODO: labelChildrenCss.color */ })
      : children;

  const label = (
    <div
      stylex={{
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <div
        {...stylex.props(
          labelStyles.base,
          labelAlign === LabelAlign.Right && labelStyles.rightAligned,
          labelAlign === LabelAlign.Center && labelStyles.centered,
          iconPosition === IconPosition.Right && labelStyles.directionReversed,
          loading && labelStyles.loading,
        )}
      >
        {actualIcon && (
          <div
            stylex={{
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
            }}
          >
            {actualIcon}
          </div>
        )}
        {actualChildren && (
          <div stylex={{ margin: "auto" }}>{actualChildren}</div>
        )}
      </div>

      {/* Increase hit area, especially for naked buttons */}
      <div
        stylex={{
          position: "absolute",
          top: "calc(var(--spacing) / -2)",
          right: "calc(var(--spacing) / -2)",
          bottom: "calc(var(--spacing) / -2)",
          left: "calc(var(--spacing) / -2)",
          padding: "calc(var(--spacing) / -2)",
        }}
      />

      {loading && (
        <div
          stylex={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
          }}
        >
          <LoadingIndicator
            size={Math.floor(size * 20)}
            style={commonStyles.marginAuto}
            showDelay={0}
          />
        </div>
      )}
    </div>
  );

  const style = [
    styles.sizeVar(size),
    styles.base,
    variantStyles[variant],
    active && variantActiveStyles[variant],
    disabled && styles.disabled,
    disabled && variantDisabledStyles[variant],
    styleProp,
  ];

  const passDownProps = omit(otherProps, [
    "hoverContent",
    "hoverOptions",
    "hoverBoxRef",
    "hoverContentMaxWidth",
    "className",
    "style",
  ]);

  return (
    <Fragment>
      {to ? (
        <Link
          {...passDownProps}
          ref={ref}
          onMouseDown={onMouseDown === true || undefined}
          onClick={onClick}
          to={to}
          data-test-load-count={dataTestLoadCount}
          variant={(variant === Variant.Discrete && "discrete") || undefined}
          style={style}
        >
          {label}
        </Link>
      ) : (
        <button
          {...stylex.props(style)}
          {...passDownProps}
          type={type}
          ref={ref ? mergeRefs([ref, innerRef]) : innerRef}
          tabIndex={0}
          data-test-load-count={dataTestLoadCount}
        >
          {label}
        </button>
      )}

      {showOptions &&
        options?.map((option) => (
          <Button
            key={getKey(option)}
            variant={variant}
            style={styles.optionButton}
            labelAlign={labelAlign}
            {...option}
          />
        ))}
    </Fragment>
  );
}
