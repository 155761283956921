import { redirect, RouteObject } from "react-router";
import getLazyRouteProps from "common/getLazyRouteProps.ts";
import notFoundErrorThrowingRoute from "common/notFoundErrorThrowingRoute.ts";
import eventsRoute from "./Events/route.ts";
import invitationsRoute from "./Invitations/route.ts";
import accountsRoute from "./Accounts/route.ts";
import venuesRoute from "./Venues/route.ts";
import usersRoute from "./Users/route.ts";
import newsRoute from "./ManagerNews/route.ts";
import supportRoute from "./Support/route.ts";
import eventTemplatesRoute from "./EventTemplates/route.ts";
import permanentGuestListsRoute from "./PermanentGuestLists/route.ts";
import agreementTemplatesRoute from "./AgreementTemplates/route.ts";
import newslettersRoute from "./Newsletters/route.ts";
import maintenanceRoute from "./Maintenance/route.ts";
import industriesRoute from "./Industries/route.ts";
import invoiceReminderRoute from "./InvoiceReminder/route.ts";
import pagesRoute from "./Pages/route.ts";
import transactionsRoute from "./Transactions/route.ts";
import invoicesRoute from "./Invoices/route.ts";
import screenRecordingsRoute from "./ScreenRecordings/route.ts";
import referenceCustomersRoute from "./ReferenceCustomers/route.ts";
import testimonialsRoute from "./Testimonials/route.ts";
import productsRoute from "./Products/route.ts";
import paymentServiceProvidersRoute from "./PaymentServiceProviders/route.ts";
import countriesRoute from "./Countries/route.ts";

export default {
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./ManagerRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { index: true, loader: () => redirect("events") },
        { path: "events", ...eventsRoute },
        { path: "invitations", ...invitationsRoute },
        { path: "event-templates", ...eventTemplatesRoute },
        { path: "venues", ...venuesRoute },
        { path: "users", ...usersRoute },
        { path: "support", ...supportRoute },
        { path: "news", ...newsRoute },
        {
          path: "permanent-guest-lists",
          ...permanentGuestListsRoute,
        },
        { path: "accounts", ...accountsRoute },
        { path: "agreement-templates", ...agreementTemplatesRoute },
        { path: "newsletters", ...newslettersRoute },
        { path: "maintenance", ...maintenanceRoute },
        { path: "industries", ...industriesRoute },
        { path: "invoice-reminder", ...invoiceReminderRoute },
        { path: "pages", ...pagesRoute },
        { path: "transactions", ...transactionsRoute },
        { path: "invoices", ...invoicesRoute },
        { path: "screen-recordings", ...screenRecordingsRoute },
        { path: "reference-customers", ...referenceCustomersRoute },
        { path: "testimonials", ...testimonialsRoute },
        { path: "products", ...productsRoute },
        { path: "payment-service-providers", ...paymentServiceProvidersRoute },
        { path: "countries", ...countriesRoute },
        { path: "*", ...notFoundErrorThrowingRoute },
      ],
    },
  ],
} as RouteObject;
