import i18next from "i18next";
import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "./UserFriendlyMessageError.ts";

export type Options = ParentOptions;

export default class ConnectionFailedError extends UserFriendlyMessageError {
  public static className = "ConnectionFailedError";

  constructor(messageOrOptions?: string | Options, options?: Options) {
    const message =
      typeof messageOrOptions === "string"
        ? messageOrOptions
        : "Connection failed.";
    const {
      userFriendlyMessage = i18next.t(
        "ConnectionFailedError.userFriendlyMessage",
        {
          defaultValue: message,
        },
      ),
      ...otherProps
    } =
      (typeof messageOrOptions === "object" && messageOrOptions) ||
      options ||
      {};
    super(message, { userFriendlyMessage, ...otherProps });
    this.name = ConnectionFailedError.className;
  }
}
