type Extras = { [name: string]: any };

type User = { id: string };

type HandlerOptions = {
  error: any;
  user?: User;
  extras?: Extras;
  reference?: string;
};

export type LoggerHandler = (
  options: HandlerOptions,
) => { error: any; extras?: Extras; reference?: string } | void;

class Logger {
  private handlers: LoggerHandler[] = [];

  private errorReferences = new WeakMap<any, string>();

  private linkedErrorReferences = new WeakMap<any, string>();

  private user: User;

  private extras: Extras = {};

  addHandler(handler: LoggerHandler) {
    this.handlers.push(handler);
  }

  removeHandler(handler: LoggerHandler) {
    this.handlers = this.handlers.filter((h) => h !== handler);
  }

  error(error: any, { extras }: { extras?: Extras } = {}) {
    if (!error) return;

    const actualExtras = {
      ...this.extras,
      ...(extras || {}),
    };

    const linkedErrorReference = this.linkedErrorReferences.get(error);
    if (linkedErrorReference)
      actualExtras.linkedErrorReference = linkedErrorReference;

    let handlerInputOutput: HandlerOptions = {
      // eslint-disable-next-line no-new-wrappers
      error: (typeof error === "string" && new String(error)) ||
        (typeof error === "object" && error) || { error }, // Because primitive types are not supported as keys in WeakMap
      user: this.user,
      extras: actualExtras,
    };

    // Don´t handle errors again if reference has already been set
    if (!this.errorReferences.has(error)) {
      this.handlers.forEach((handler) => {
        handlerInputOutput = handler(handlerInputOutput) || handlerInputOutput;
      });

      if (handlerInputOutput.reference)
        this.errorReferences.set(
          handlerInputOutput.error,
          handlerInputOutput.reference,
        );
    }

    return handlerInputOutput.error;
  }

  warn(error: any) {
    if (console.warn) console.warn(error);
    else console.log(error);
  }

  getErrorReference(error: any) {
    return this.errorReferences.get(error);
  }

  setLinkedErrorReference(error: any, reference: string) {
    this.linkedErrorReferences.set(error, reference);
  }

  setUser(user: User) {
    this.user = user;
  }

  setExtra(name: string, value: any) {
    this.extras[name] = value;
  }
}

const logger = new Logger();

export default logger;
