import { RouteObject } from "react-router";
import homeRoute from "./ReferenceCustomersHome/route.ts";
import newRoute from "./NewReferenceCustomer/route.ts";
import industryRoute from "./ReferenceCustomer/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./ScreenRecordingsRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [{ path: "new", ...newRoute }, industryRoute],
    },
  ],
} as RouteObject;
