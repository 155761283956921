import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./EventTemplateDetails/route.ts";
import guestListsRoute from "./EventTemplateGuestLists/route.ts";
import fieldsRoute from "./EventTemplateFields/route.ts";
import permissionsRoute from "./EventTemplatePermissions/route.ts";
import deleteRoute from "./DeleteEventTemplate/route.ts";

export default {
  path: ":eventTemplateId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./EventTemplateRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "guest-lists", ...guestListsRoute },
        { path: "fields", ...fieldsRoute },
        { path: "permissions", ...permissionsRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
