import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./AccountDetails/route.ts";
import invoicesRoute from "./AccountInvoices/route.ts";
import purchaseDiscountVoucersRoute from "./AccountPurchaseDiscountVouchers/route.ts";
import gdprRoute from "./AccountGDPR/route.ts";
import paymentsRoute from "./AccountPayments/route.ts";
import deleteRoute from "./DeleteAccount/route.ts";
import subscriptionRoute from "./AccountSubscription/route.ts";
import managerNotesRoute from "./AccountManagerNotes/route.ts";
import paymentMethodsRoute from "./AccountPaymentMethods/route.ts";
import domainsRoute from "./AccountDomains/route.ts";

export default {
  path: ":accountId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./AccountRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "invoices", ...invoicesRoute },
        { path: "purchase-discount-vouchers", ...purchaseDiscountVoucersRoute },
        { path: "gdpr", ...gdprRoute },
        { path: "payments", ...paymentsRoute },
        { path: "delete", ...deleteRoute },
        { path: "subscription", ...subscriptionRoute },
        { path: "manager-notes", ...managerNotesRoute },
        { path: "payment-methods", ...paymentMethodsRoute },
        { path: "domains", ...domainsRoute },
      ],
    },
  ],
} as RouteObject;
