import * as stylex from "@stylexjs/stylex";
import {
  ReactNode,
  Ref,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { mergeRefs } from "react-merge-refs";
import { useResolvedPath } from "react-router";
import { useLinkHandler } from "./LinkHandlerContext.tsx";
import { useRoutePrefetcher } from "./RoutePrefetcherContext.ts";

const styles = stylex.create({
  base: { cursor: "pointer" },
});

const variantStyles = stylex.create({
  primary: { color: "var(--main-color)" },
  discrete: { color: "var(--note-color)" },
});

enum Variant {
  Primary = "primary",
  Discrete = "discrete",
}

export default function Link({
  ref,
  children,
  variant = Variant.Primary,
  to: rawTo,
  target,
  onClick,
  onMouseDown,
  style,
  ...otherProps
}: {
  ref?: Ref<HTMLAnchorElement>;
  children: ReactNode;
  variant?: Variant[keyof Variant];
  to?: string;
  target?: string;
  onMouseDown?: boolean;
  onClick?: () => void;
  style?: stylex.StyleXStyles;
}) {
  const linkHandler = useLinkHandler();
  const prefetchRoute = useRoutePrefetcher();
  const resolvedTo = useResolvedPath(rawTo);
  const to =
    rawTo &&
    (rawTo.startsWith("http://") ||
      rawTo.startsWith("https://") ||
      rawTo.startsWith("mailto"))
      ? rawTo
      : `${resolvedTo.pathname}${resolvedTo.search}${resolvedTo.hash}`;

  // Since react seems to trigger onMouseDown on an element that appears under the mouse even if that element wasn't there when the press started
  const innerRefCleanupRef = useRef<() => void>(null);
  const innerRef = useCallback(
    (actualRef: HTMLAnchorElement) => {
      if (innerRefCleanupRef.current) {
        innerRefCleanupRef.current();
        innerRefCleanupRef.current = undefined;
      }

      if (actualRef && (to || onClick)) {
        const doAction = (event: MouseEvent | TouchEvent) => {
          event.preventDefault();
          onClick?.();
          if (to)
            linkHandler.navigate({
              to,
              target:
                ((event.ctrlKey || event.metaKey || event.shiftKey) &&
                  "_blank") ||
                target,
            });
        };

        let clickHandler;
        if (onMouseDown) {
          actualRef.addEventListener("touchstart", doAction, {
            passive: false,
          });
          actualRef.addEventListener("mousedown", doAction);
          clickHandler = (event: SyntheticEvent) => event.preventDefault();
        } else clickHandler = doAction;
        actualRef.addEventListener("click", clickHandler);

        innerRefCleanupRef.current = () => {
          actualRef.removeEventListener("touchstart", doAction);
          actualRef.removeEventListener("mousedown", doAction);
          actualRef.removeEventListener("click", clickHandler);
        };
      }
    },
    [to, linkHandler, onClick, onMouseDown],
  );

  const onMouseEnter = useMemo(
    () => (to && prefetchRoute ? () => prefetchRoute(to) : null),
    [to, prefetchRoute],
  );

  return (
    <a
      {...stylex.props(styles.base, variantStyles[variant], style)}
      ref={mergeRefs([ref, innerRef])}
      target={target}
      rel={target === "_blank" ? "noreferrer noopener" : undefined}
      href={to}
      onMouseEnter={onMouseEnter}
      {...otherProps}
    >
      {children}
    </a>
  );
}
