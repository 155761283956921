import { ComponentPropsWithoutRef } from "react";
import LoadingIndicator from "./LoadingIndicator.tsx";

export default function CenteredLoadingIndicator(
  props: ComponentPropsWithoutRef<typeof LoadingIndicator>,
) {
  return (
    <div
      stylex={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <LoadingIndicator {...props} stylex={{ margin: "auto" }} />
    </div>
  );
}
