export default function getUserFriendlyErrorMessage(
  error: any,
): string | undefined {
  return (
    error.userFriendlyMessage ||
    error.extensions?.userFriendlyMessage /* graphql */ ||
    (error.source?.errors /* graphql */ &&
      [...new Set(error.source.errors.map((e?: any) => e?.userFriendlyMessage))]
        .filter((message?: any) => message)
        .join("\n")) ||
    (error.name === "QuotaExceededError" /* indexeddb */ &&
      "Not enough disk space.") ||
    (error.name === "UnknownError" &&
      error.message ===
        "Internal error." /* Thrown by both edge and chrome. Reason unknown */ &&
      "Something went wrong in your browser. Please restart it and try again.") ||
    undefined
  );
}
