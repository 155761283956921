import i18next from "i18next";
import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "./UserFriendlyMessageError.ts";

export type Options = ParentOptions;

export default class AuthorizationError extends UserFriendlyMessageError {
  public static className = "AuthorizationError";

  constructor(messageOrOptions?: string | Options, options?: Options) {
    const message =
      typeof messageOrOptions === "string"
        ? messageOrOptions
        : "Authorization error.";
    const {
      userFriendlyMessage = i18next.t(
        "AuthorizationError.userFriendlyMessage",
        {
          defaultValue: message,
        },
      ),
    } =
      (typeof messageOrOptions === "object" && messageOrOptions) ||
      options ||
      {};
    super(message, { userFriendlyMessage });
    this.name = AuthorizationError.className;
  }
}
