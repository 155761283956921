import { ReactNode } from "react";
import { useThemes } from "./ThemesContext.tsx";
import ThemeContext from "./ThemeContext.tsx";
import { Theme } from "./Theme.ts";

export default function ThemeProvider({
  dark,
  children,
}: {
  dark?: boolean;
  children: ReactNode | ((theme: Theme) => ReactNode);
}) {
  const themes = useThemes();
  const theme = themes[dark ? "dark" : "light"];
  return (
    <ThemeContext value={theme}>
      {typeof children === "function" ? children(theme) : children}
    </ThemeContext>
  );
}
