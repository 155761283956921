import { RouteObject } from "react-router";
import homeRoute from "./InvitationsHome/route.ts";
import invitationRoute from "./ManagerInvitation/route.ts";
import newRoute from "./NewInvitation/route.ts";
import importRoute from "./ImportInvitations/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./InvitationsRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { path: "new", ...newRoute },
        { path: "import", ...importRoute },
        invitationRoute,
      ],
    },
  ],
} as RouteObject;
