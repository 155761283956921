/**
 * @generated SignedSource<<2989995a6d772d0dc059863f69e22125>>
 * @relayHash 229bb1891bfcbfa74d38ea19ec141813
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 229bb1891bfcbfa74d38ea19ec141813

import { ConcreteRequest } from 'relay-runtime';
export type UpgradeSubscriptionPlanLinkEventQuery$variables = {
  id: string;
};
export type UpgradeSubscriptionPlanLinkEventQuery$data = {
  readonly event: {
    readonly venue: {
      readonly account: {
        readonly currentUserHasAdministerPermission: boolean;
        readonly id: string;
      };
    };
  } | null | undefined;
};
export type UpgradeSubscriptionPlanLinkEventQuery = {
  response: UpgradeSubscriptionPlanLinkEventQuery$data;
  variables: UpgradeSubscriptionPlanLinkEventQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": "currentUserHasAdministerPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "permission",
          "value": "ADMINISTER"
        }
      ],
      "kind": "ScalarField",
      "name": "currentUserHasPermission",
      "storageKey": "currentUserHasPermission(permission:\"ADMINISTER\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpgradeSubscriptionPlanLinkEventQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Venue",
            "kind": "LinkedField",
            "name": "venue",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpgradeSubscriptionPlanLinkEventQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Venue",
            "kind": "LinkedField",
            "name": "venue",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "229bb1891bfcbfa74d38ea19ec141813",
    "metadata": {},
    "name": "UpgradeSubscriptionPlanLinkEventQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7f3a2e2ccf8cf7406da4caa1a5203499";

export default node;
