import { createContext, use } from "react";

export type ShareOptions = {
  dialogTitle?: string;
  title?: string;
  text?: string;
  url: string;
};

export interface Sharer {
  canShare: (options: ShareOptions) => boolean;
  share: (options: ShareOptions) => Promise<void>;
}

const SharerContext = createContext<Sharer>(null);

export const useSharer = () => use(SharerContext);

export default SharerContext;
