import { ComponentProps } from "react";
import Svg from "../Svg.tsx";

export default function ErrorIcon({
  color = "var(--text-color)",
  ...otherProps
}: {
  color?: string;
} & Omit<ComponentProps<typeof Svg>, "viewBox">) {
  return (
    <Svg viewBox="0 0 980 926.3" {...otherProps}>
      <path
        fill={color}
        d="M468.1.5c-82.7 10.4-170.6 45.4-230.6 92.1l-6.2 4.9L216.8 76c-23.6-34.5-27-38.4-38.1-44.9-20.5-12.2-46.4-10.9-65.1 3.4C87.1 54 22 157.7 6.2 205.2c-16.1 48 0 105 40.7 143.9L63 364.7l-4.2 21.5c-2.8 15.3-4.2 36.6-4.2 77 0 48 .8 59.9 5.7 85.3 38.7 193 185.4 337.7 377.9 372.7 36.6 6.7 121.6 6.7 158.2 0 174-31.6 314.9-156.6 365.4-324.2 14.8-48.8 18.2-73.9 18.2-133.8s-3.4-85.1-18.2-133.8C912.1 163.9 775.4 40.8 604 6.8c-21.5-4.4-39.9-6-77.5-6.5-27.1-.5-53.5-.3-58.4.2zm78.3 65.9c57.6 4.7 104.5 18.2 155.1 44.4 105 54.2 182.3 156.9 206.2 273.1 9.6 47.5 9.6 111.3 0 159-15 73.7-55.2 148.1-108.9 201.8C745 798.3 670.6 838.5 597 853.5c-47.7 9.6-111.5 9.6-159 0-116.2-23.9-218.9-101.1-273.1-206.2-35.3-68-50.8-145.2-44.4-216.3 4.1-43.6 2.3-40.5 25.7-40.5 73.1 0 134.9-54.5 145.8-128.6 3.9-25.9.5-46.2-14.3-80.7l-11.1-26.4 5.5-4.9c10.4-9.9 44.6-31.1 68.7-43.1C391 81.5 443.4 68.5 510.9 64.3c2.8-.2 18.9.8 35.5 2.1zm-367.3 73.4c10.9 16.9 25.7 43.1 32.9 58.3 12.7 25.9 13.5 28.8 13.5 47.2-.3 15.8-1.6 21.8-7 32.4-25.7 49.3-84 61.5-123.7 25.7-18.4-16.6-27.5-36-27.5-59.4 0-15.8 1.6-21 10.6-40.2 11.9-25.2 31.1-56.5 53.7-87.9l15.8-21.8 6 7.5c3.4 4.2 15.1 21.4 25.7 38.2z"
      />
      <path
        fill={color}
        d="M335 284.3c-27.8 7-54.5 28.8-66.1 53.9-11.7 25.4-7.5 44.6 11.7 53.7 17.6 8.6 36.8.8 45.1-18.1 7-15.8 18.1-24.6 31.4-24.6 15 0 24.6 6.7 32.2 22.6 7.3 15.6 14.5 21.5 28.8 23.1 20.8 2.6 38.9-15.8 35.8-36-5.7-39.2-46.4-73.4-89.7-75.7-10.8-.7-23.7-.2-29.2 1.1zm317.2.8c-30.9 9.1-50 24.9-63.8 52.4-7.5 15.3-9.3 21.3-8 28.5 3.1 19.5 20.5 32.2 38.9 28.8 12.5-2.3 18.2-7.3 26.2-23.6 8.3-16.3 16.6-22 32.4-22 13 0 24.1 8.8 31.1 24.9 12.5 28 48.2 29 60.4 1.6 4.7-10.1 4.9-12.7 1.8-23.1-4.2-15.6-20-39.2-31.6-48.2-24.9-19.1-61-26.9-87.4-19.3zM461.6 547.5c-58.1 10.6-104 30.3-148.6 63.3-23.3 17.4-30.9 25.9-33.2 37.6-3.4 18.4 13.2 37.9 32.2 37.9 10.4 0 15.3-2.3 35.5-18.2 100.9-78.3 239.1-78.3 339.7 0 20.2 15.8 25.2 18.2 35.5 18.2 19.2 0 35.5-19.5 31.9-38.1-2.1-11.7-12.2-22.3-37.1-40.5-41.2-29.6-93.6-51.6-144.2-60.2-27.7-4.7-86.3-4.7-111.7 0z"
      />
    </Svg>
  );
}
