import { useLocation } from "react-router";
import { useCallback, useEffect, useState } from "react";
import CookieManager from "./CookieManager.ts";
import { useCookieManager } from "./CookieManagerContext.tsx";
import deepEqual from "./deepEqual.ts";

export default function useCookieState<T>({
  name,
  allowedValues,
  contentType,
  currentPath,
  ...otherProps
}: {
  allowedValues?: T[];
  currentPath?: boolean;
} & Omit<
  Parameters<InstanceType<typeof CookieManager>["set"]>[0],
  "value"
>): ReturnType<typeof useState<T>> {
  const location = useLocation();
  const cookieManager = useCookieManager();
  const path = currentPath ? location.pathname : otherProps.path;

  const getActualValue = (cookieValue: any) =>
    !allowedValues
      ? cookieValue
      : allowedValues.find((v) => deepEqual(v, cookieValue));

  const [valueState, setValueState] = useState<T>(() =>
    getActualValue(cookieManager.get({ name, contentType, path })),
  );

  useEffect(() => {
    const subscription = cookieManager.subscribe({
      name,
      subscriber: (value) => setValueState(getActualValue(value)),
    });
    return () => subscription.unsubscribe();
  }, [name, cookieManager]);

  return [
    valueState,
    useCallback(
      (value: T | ((oldValue: T) => T)) =>
        cookieManager.set({
          ...otherProps,
          name,
          value:
            typeof value === "function"
              ? (value as (oldValue: T) => T)(
                  getActualValue(cookieManager.get({ name, contentType })),
                )
              : value,
          contentType,
          path,
        }),
      [cookieManager],
    ),
  ];
}
