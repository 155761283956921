type Options = {
  key: string;
  lngs: any;
  options: any;
};

export default class MissingTranslationError extends Error {
  public static className = "MissingTranslationError";

  public key: string;

  public lngs: any;

  public options: any;

  constructor(messageOrOptions: string | Options, options?: Options) {
    super(
      typeof messageOrOptions === "string"
        ? messageOrOptions
        : "Missing translation.",
    );

    this.name = MissingTranslationError.className;

    const actualOptions =
      (typeof messageOrOptions === "object" && messageOrOptions) || options;

    this.key = actualOptions?.key;
    this.lngs = actualOptions?.lngs;
    this.options = actualOptions?.options;
  }
}
