import { RouteObject } from "react-router";
import homeRoute from "./VenuesHome/route.ts";
import newRoute from "./NewVenue/route.ts";
import venueRoute from "./Venue/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./VenuesRouteErrorBoundary.tsx")).default,
      }),
      children: [{ path: "new", ...newRoute }, venueRoute],
    },
  ],
} as RouteObject;
