import { RouteObject } from "react-router";
import homeRoute from "./AgreementTemplatesHome/route.ts";
import newRoute from "./NewAgreementTemplate/route.ts";
import templateRoute from "./AgreementTemplate/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./AgreementTemplatesRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [{ path: "new", ...newRoute }, templateRoute],
    },
  ],
} as RouteObject;
