import { RouteObject } from "react-router";
import homeRoute from "./ScreenRecordingsHome/route.ts";
import industryRoute from "./ScreenRecording/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./ScreenRecordingsRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [industryRoute],
    },
  ],
} as RouteObject;
