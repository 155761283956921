import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./InvitationDetails/route.ts";
import shareRoute from "./ShareInvitation/route.ts";
import deleteRoute from "./DeleteInvitation/route.ts";

export default {
  path: ":invitationId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./ManagerInvitationErrorBoundary.tsx"))
          .default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "share", ...shareRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
