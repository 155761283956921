import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { RouteObject, redirect } from "react-router";
import detailsRoute from "./PageDetails/route.ts";

export default {
  path: ":pageId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./PageRouteErrorBoundary.tsx")).default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
      ],
    },
  ],
} as RouteObject;
