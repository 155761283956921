/**
 * @generated SignedSource<<6ffa7c2aeb896b53414bf140d0b0cebb>>
 * @relayHash 2d48deb7222bbd713a5209214aa3833e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2d48deb7222bbd713a5209214aa3833e

import { ConcreteRequest } from 'relay-runtime';
export type Role = "ADMINISTRATOR" | "MANAGER" | "SUPPORT" | "%future added value";
export type SecurityManagerLogInQuery$variables = Record<PropertyKey, never>;
export type SecurityManagerLogInQuery$data = {
  readonly me: {
    readonly id: string;
    readonly roles: ReadonlyArray<Role | null | undefined>;
  } | null | undefined;
};
export type SecurityManagerLogInQuery = {
  response: SecurityManagerLogInQuery$data;
  variables: SecurityManagerLogInQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SecurityManagerLogInQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SecurityManagerLogInQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "2d48deb7222bbd713a5209214aa3833e",
    "metadata": {},
    "name": "SecurityManagerLogInQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "93be4b1b8a83f43f49337dce90a86989";

export default node;
