import { Component, ReactElement } from "react";
import FocusableContext from "./FocusableParentContext.tsx";

export interface Focusable {
  focus: () => void;
  wantsAutoFocus: () => boolean;
  autoFocus?: () => void;
}

export interface FocusableParent {
  addFocusable: (focusable: Focusable) => void;
  removeFocusable: (focusable: Focusable) => void;
}

class Focuser
  extends Component<{
    autoFocus?: boolean;
    children:
      | ReactElement
      | (({ ref }: { ref: (r: any) => void }) => void)
      | (({ ref }: { ref: (r: any) => void }) => void);
    parentFocusable?: FocusableParent;
    autoFocusOnMount?: boolean;
  }>
  implements FocusableParent, Focusable
{
  private focusables: Focusable[] = [];

  private ref?: any | null;

  componentDidMount() {
    this.props.parentFocusable?.addFocusable(this);
    if (this.props.autoFocusOnMount) this.autoFocus();
  }

  componentWillUnmount() {
    if (this.props.parentFocusable?.removeFocusable)
      this.props.parentFocusable?.removeFocusable(this);
  }

  private setRef = (ref: any) => {
    this.ref = ref;
  };

  focus = () => {
    if (this.ref) return this.ref.focus();
    if (this.props.children?.focus) return this.props.children.focus();
    if (this.focusables.length > 0) return this.focusables[0].focus();
    return false;
  };

  autoFocus = () => {
    // Hack because just one of these, or doing there in componentDidUpdate does not really work.
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (this.props.autoFocus && this.ref) this.ref.focus();
        else {
          for (let { length } = this.focusables, i = 0; i < length; i += 1) {
            const focusable = this.focusables[i];
            if (focusable.autoFocus && focusable.wantsAutoFocus()) {
              focusable.autoFocus();
              break;
            }
          }
        }
      });
    });
  };

  wantsAutoFocus() {
    return !!this.props.autoFocus;
  }

  addFocusable(focusable: Focusable) {
    this.focusables.push(focusable);
  }

  removeFocusable(focusable: Focusable) {
    const index = this.focusables.indexOf(focusable);
    if (index !== -1) this.focusables.splice(index, 1);
  }

  render() {
    const { children } = this.props;

    return (
      <FocusableContext value={this}>
        {typeof children === "function"
          ? children({ ref: this.setRef })
          : children}
      </FocusableContext>
    );
  }
}

export default function FocuserParent(props) {
  return (
    <FocusableContext.Consumer>
      {(focusable: Focusable) => (
        <Focuser parentFocusable={focusable} {...props} />
      )}
    </FocusableContext.Consumer>
  );
}
