import { createContext, use } from "react";
import createTheme from "./createTheme.ts";
import { Theme } from "./Theme.ts";

type ThemesContextValue = {
  light: Theme;
  dark: Theme;
};

const ThemesContext = createContext<ThemesContextValue>(undefined!);

const defaultThemes = {
  light: createTheme({ dark: false }),
  dark: createTheme({ dark: true }),
};

const Provider = ({ value, ...otherProps }: { value?: ThemesContextValue }) => (
  <ThemesContext.Provider value={value || defaultThemes} {...otherProps} />
);

export const useThemes = () => use(ThemesContext);

export default { ...ThemesContext, Provider };
