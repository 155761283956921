import { redirect, RouteObject } from "react-router";
import getLazyRouteProps from "common/getLazyRouteProps.ts";
import guestsRoute from "./EventGuests/route.ts";
import settingsRoute from "./EventSettings/route.ts";
import statisticsRoute from "./EventStatistics/route.ts";
import logRoute from "./EventLog/route.ts";

export default {
  path: ":eventId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./EventRouteErrorBoundary.tsx")).default,
      }),
      children: [
        { index: true, loader: () => redirect("guests") },
        { path: "guests", ...guestsRoute },
        { path: "statistics", ...statisticsRoute },
        { path: "log", ...logRoute },
        { path: "settings", ...settingsRoute },
      ],
    },
  ],
} as RouteObject;
