import { createContext, use } from "react";
import { SafeAreaInsets } from "./safeAreaInsets.ts";

const SafeAreaInsetsContext = createContext<SafeAreaInsets>({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export const useSafeAreaInsets = () => use(SafeAreaInsetsContext);

export default SafeAreaInsetsContext;
