export function createConfig({
  assetPath = "js",
  restApiBaseUrl,
  graphqlEndpointUrl,
  graphqlSubscriptionsEndpointUrl,
  stripePublishableKey,
  assetsImgixBaseUrl,
  assetsBaseUrl,
  filesBaseUrl,
  filesImgixBaseUrl,
}: {
  assetPath?: string;
  restApiBaseUrl: string;
  graphqlEndpointUrl: string;
  graphqlSubscriptionsEndpointUrl: string;
  stripePublishableKey: string;
  assetsImgixBaseUrl: string;
  assetsBaseUrl: string;
  filesBaseUrl: string;
  filesImgixBaseUrl: string;
}) {
  return {
    assetPath,
    host: "attendium.com",
    logInPath: "/manager",
    stripe: {
      publishableKey: stripePublishableKey,
    },
    assets: {
      imgixBaseUrl: assetsImgixBaseUrl,
      baseUrl: assetsBaseUrl,
    },
    files: {
      baseUrl: filesBaseUrl,
      imgixBaseUrl: filesImgixBaseUrl,
    },
    invoices: {
      baseUrl: `${restApiBaseUrl}invoices`,
    },
    tickets: {
      pdfBaseUrl: `${restApiBaseUrl}tickets`,
    },
    guestConfirmations: {
      pdfBaseUrl: `${restApiBaseUrl}guest-confirmations`,
    },
    guestEntryConfirmations: {
      pdfBaseUrl: `${restApiBaseUrl}guest-entry-confirmations`,
    },
    graphql: {
      endpointUrl: graphqlEndpointUrl,
      subscriptionsEndpointUrl: graphqlSubscriptionsEndpointUrl,
    },
  };
}

export type Config = ReturnType<typeof createConfig>;
