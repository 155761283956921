import { RouteObject } from "react-router";
import homeRoute from "./PaymentServiceProvidersHome/route.ts";
import providerRoute from "./PaymentServiceProvider/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./PaymentServiceProvidersRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [providerRoute],
    },
  ],
} as RouteObject;
