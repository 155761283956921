import { RouteObject } from "react-router";
import templateRoute from "./EventTemplate/route.ts";
import newRoute from "./NewEventTemplate/route.ts";

export default {
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./EventTemplatesRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [{ path: "new", ...newRoute }, templateRoute],
    },
  ],
} as RouteObject;
