import { RouteObject } from "react-router";
import homeRoute from "./UsersHome/route.ts";
import newRoute from "./NewUser/route.ts";
import userRoute from "./User/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./UsersRouteErrorBoundary.tsx")).default,
      }),
      children: [{ path: "new", ...newRoute }, userRoute],
    },
  ],
} as RouteObject;
