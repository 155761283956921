import { packageName, packageVersion } from "buildConfig.ts";
import errorMappingFetch from "common/errorMappingFetch.ts";

export default function fetchGraphql(
  url: string,
  options: RequestInit,
): ReturnType<typeof fetch> {
  const isFormData =
    typeof FormData !== "undefined" && options.body instanceof FormData;

  const request = {
    method: "POST",
    ...options,
    headers: {
      Accept: "application/json",
      Client: `${packageName} ${packageVersion}`,
      ...options.headers,
    },
    body: isFormData ? options.body : JSON.stringify(options.body),
  };

  if (!isFormData) request.headers["Content-Type"] = "application/json";

  return errorMappingFetch(url, request).then(async (response) => {
    const json = await response.json();
    // If there are any errors, fail the whole request. This is not ideal but there is not yet any great way to handle partial errors in relay (https://github.com/facebook/relay/issues/1913) and this is better than having requests swallow errors
    return json.errors
      ? {
          ...json,
          data: null,
          errors: json.errors.map((error: any) => ({
            ...error,
            userFriendlyMessage:
              error.extensions?.userFriendlyMessage === true
                ? error.message
                : error.extensions?.userFriendlyMessage,
          })),
        }
      : json;
  });
}
