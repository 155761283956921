/**
 * @generated SignedSource<<6e5170b408bd2dc82b12f22f49617f7a>>
 * @relayHash 07805106b367387ea3df63328a022343
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 07805106b367387ea3df63328a022343

import { ConcreteRequest } from 'relay-runtime';
export type ScreenRecorderQuery$variables = Record<PropertyKey, never>;
export type ScreenRecorderQuery$data = {
  readonly screenRecordingEnabled: boolean;
};
export type ScreenRecorderQuery = {
  response: ScreenRecorderQuery$data;
  variables: ScreenRecorderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "screenRecordingEnabled",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScreenRecorderQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ScreenRecorderQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "07805106b367387ea3df63328a022343",
    "metadata": {},
    "name": "ScreenRecorderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ad62712864a37ca2084d30391bc3114b";

export default node;
