import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { healthCheckPath } from "buildConfig.ts";
import { RouteObject } from "react-router";
import guestListAppRoute from "./GuestListManagement/route.ts";
import onlineInvitationsRoute from "./Invitations/route.ts";
import recoverAccountRoute from "./RecoverAccount/route.ts";
import unsubscribeRoute from "./Unsubscribe/route.ts";
import signUpRoute from "./SignUp/route.ts";
import contactUsRoute from "./ContactUs/route.ts";
import pricingRoute from "./Pricing/route.ts";
import testimonialsRoute from "./Testimonials/route.ts";
import setUpUserRoute from "./SetUpUser/route.ts";
import reviewUsRoute from "./ReviewUs/route.ts";
import newsRoute from "./MainNews/route.ts";
import compareRoute from "./Compare/route.ts";
import defaultRoute from "./Default/route.ts";
import homeRoute from "./Home/route.ts";
import ticketingRoute from "./Ticketing/route.ts";
import agreementsRoute from "./Agreements/route.ts";
import agreementTemplatesRoute from "./AgreementTemplates/route.ts";
import privacySettingsRoute from "./PrivacySettings/route.ts";

export default {
  children: [
    { path: healthCheckPath, element: "Ok" },
    { path: "*", ...defaultRoute },
    {
      lazy: () => getLazyRouteProps(import("./index.tsx")),
      children: [
        { index: true, ...homeRoute },
        { path: "guest-list-management", ...guestListAppRoute },
        { path: "invitations", ...onlineInvitationsRoute },
        { path: "ticketing", ...ticketingRoute },
        { path: "sign-up", ...signUpRoute },
        { path: "recover-account", ...recoverAccountRoute },
        { path: "compare", ...compareRoute },
        { path: "review-us", ...reviewUsRoute },
        { path: "set-up-user", ...setUpUserRoute },
        { path: "testimonials", ...testimonialsRoute },
        { path: "contact-us", ...contactUsRoute },
        { path: "news", ...newsRoute },
        { path: "pricing", ...pricingRoute },
        { path: "unsubscribe", ...unsubscribeRoute },
        { path: "agreements", ...agreementsRoute },
        { path: "agreement-templates", ...agreementTemplatesRoute },
        { path: "privacy-settings", ...privacySettingsRoute },
      ],
    },
  ],
} as RouteObject;
