import i18next from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { defaultLocale, locales } from "buildConfig.ts";
import logger from "./logger.ts";
import MissingTranslationError from "./MissingTranslationError.ts";

export default async function initI18next({ locale }: { locale: string }) {
  await i18next
    .use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`../locales/${language}/${namespace}.json`),
      ),
    )
    .init({
      supportedLngs: locales.map((l) => l.code),
      lng: locale,
      fallbackLng: defaultLocale,
      interpolation: {
        escapeValue: false,
      },

      saveMissing: true,
      missingKeyHandler: (
        lngs,
        ns,
        key,
        fallbackValue,
        updateMissing,
        options,
      ) => logger.error(new MissingTranslationError({ key, lngs, options })),
    });

  i18next.services.formatter.add("lowercase", (value, lng, options) =>
    value.toLowerCase(),
  );

  i18next.use(initReactI18next);

  return i18next;
}
