import { RouteObject } from "react-router";
import eventRoute from "./Event/route.ts";
import homeRoute from "./EventsHome/route.ts";
import newRoute from "./NewEvent/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./EventsRouteErrorBoundary.tsx")).default,
      }),
      children: [{ path: "new", ...newRoute }, eventRoute],
    },
  ],
} as RouteObject;
