import { useCookieManager } from "../CookieManagerContext.tsx";
import useConsent from "../useConsent.tsx";
import cookieConfigurations from "../cookieConfigurations.ts";
import TrackingInfo from "./TrackingInfo.ts";

export default function useGetTrackingInfo(): () => TrackingInfo | null {
  const cookieManager = useCookieManager();
  const [consent] = useConsent();

  return () => {
    const fbc = cookieManager.get({ name: "_fbc" });
    const fbp = cookieManager.get({ name: "_fbp" });
    const gclid = cookieManager.get(cookieConfigurations.gclid);
    const wbraid = cookieManager.get(cookieConfigurations.wbraid);

    return fbc || fbp || gclid || wbraid
      ? {
          source: "WEBSITE",
          sourceUrl: global.document?.location.href,
          fbc,
          fbp,
          gclid,
          wbraid,
          consent,
        }
      : null;
  };
}
