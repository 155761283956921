import { RouteObject } from "react-router";
import getLazyRouteProps from "common/getLazyRouteProps.ts";
import homeRoute from "./AccountPaymentMethodsHome/route.ts";
import newRoute from "./NewAccountPaymentMethod/route.ts";

export default {
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    { index: true, ...homeRoute },
    { path: "new", ...newRoute },
  ],
} as RouteObject;
