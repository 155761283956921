import { fetchQuery, graphql } from "relay-runtime";
import { BehaviorSubject } from "rxjs";
import Auth from "./Auth.ts";
import cookieConfigurations from "./cookieConfigurations.ts";
import { base64Encode } from "./base64.ts";
import createRelayEnvironment from "./createRelayEnvironment.ts";
import logger from "./logger.ts";
import CookieManager from "./CookieManager.ts";
import Tracking from "./tracking/Tracking.ts";
import { Config } from "./config.ts";

export enum LoginMethod {
  UsernameAndPassword = "UsernameAndPassword",
  Automatic = "Automatic",
}

export default class SecurityManager {
  private config: Config;

  private cookieManager: CookieManager;

  private localeSubject: BehaviorSubject<string>;

  private tracking?: Tracking;

  public constructor({
    config,
    cookieManager,
    localeSubject,
    tracking,
  }: {
    config: Config;
    cookieManager: CookieManager;
    localeSubject: BehaviorSubject<string>;
    tracking?: Tracking;
  }) {
    this.config = config;
    this.cookieManager = cookieManager;
    this.localeSubject = localeSubject;
    this.tracking = tracking;

    const auth = this.getAuth();
    if (auth) {
      logger.setUser({ id: auth.userId });
      this.trackLogin(LoginMethod.Automatic);
    }
  }

  private trackLogin(method: LoginMethod) {
    this.tracking?.trackStandardEvent({
      googleName: "login",
      props: { method },
    });
    this.tracking?.trackCustomEvent({ metaName: "Login", props: { method } });
  }

  public getAuth(): Auth | undefined | null {
    return this.cookieManager.get(cookieConfigurations.auth);
  }

  private setAuth(auth: Auth) {
    if (auth)
      this.cookieManager.set({ ...cookieConfigurations.auth, value: auth });
    else this.cookieManager.delete(cookieConfigurations.auth);
    logger.setUser({ id: auth?.userId });
  }

  public logOut() {
    this.setAuth(null);
  }

  public subscribeToAuth(subscriber: (auth: Auth) => void) {
    return this.cookieManager.subscribe({
      ...cookieConfigurations.auth,
      subscriber,
    });
  }

  public async logIn({
    username,
    password,
  }: {
    username?: string;
    password?: string;
  }) {
    const actualAuth =
      username !== undefined &&
      password !== undefined &&
      base64Encode(`${username}:${password}`);

    const relayEnvironment = createRelayEnvironment({
      auth: { auth: actualAuth },
      locale: this.localeSubject.getValue(),
      config: this.config,
    });

    const { me } = await fetchQuery(
      relayEnvironment,
      graphql`
        query SecurityManagerLogInQuery {
          me {
            id
            roles
          }
        }
      `,
      {},
    ).toPromise();

    this.setAuth({
      auth: actualAuth,
      userId: me.id,
      userRoles: me.roles,
    });

    this.trackLogin(LoginMethod.UsernameAndPassword);
  }
}
