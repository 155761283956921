import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./UserDetails/route.ts";

export default {
  path: ":userId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./UserRouteErrorBoundary.tsx")).default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
      ],
    },
  ],
} as RouteObject;
